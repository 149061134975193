export const DEFAULT_TEXT_COLOR = 'rgb(163, 107, 122)';
export const TITLE_QUESTION_STYLE = {
    fontWeight: 600,
    fontSize: '1.15rem',
    fontStyle: 'italic',
    color: DEFAULT_TEXT_COLOR,
};
export const DESCRIPTION_QUESTION_STYLE = {
    fontWeight: 600,
    fontSize: '1rem',
    fontStyle: 'italic',
    color: DEFAULT_TEXT_COLOR,
};
export const SUBMIT_BTN_STYLE = {};
export const TITLE_IMAGE_STYLE = { maxWidth: "100%" };
