import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
    height: 200,
    backgroundSize: 'contain'
  },
  feedback:{
    minWidth: 350,
  },
}));

export default function Item(props){

  const classes = useStyles();
  const item = props.item;
  return (
    <React.Fragment>
      <Card className={classes.root}>  
        <CardMedia
          image={item.thumb_link}
          title={item.name}
          className={classes.cover}
        />
        <div className={classes.details}>
          <CardContent>
            <Typography  variant="h6" >
              {item.brand}
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
              {item.name}
            </Typography>
            <Typography gutterBottom variant="h5" component="h2" style={props.returning ? {textDecoration: 'line-through'} : {}}>
              ${item.price/100}
            </Typography>
          </CardContent>
       
        </div> 
      </Card>
    </React.Fragment>
  );
}





