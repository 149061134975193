import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

import {Box, CardMedia, Grid} from '@material-ui/core'

import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {Link} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 429,
        height: 527,
        maxWidth: '100%'
    },
    feedback:{
        minWidth: 350,
    },
}));

const borderStyle = {marginTop: '20px', border: '1px solid #dadada'}
const titleStyle = {width: '100%', height: '50px', backgroundColor: '#dadada', paddingTop: '12px', paddingLeft: '12px', color: 'rgba(0, 0, 0, 0.54)'}

export default function CustomBoxWithLabel(props){
    const classes = useStyles();

    return (
        <Grid container spacing={1} style={borderStyle} >
            <Grid item xs={12} style={{padding: '0'}}>
                <Box style={titleStyle}>
                    {props.title}
                </Box>
            </Grid>
            <Grid item xs={12} >
              {props.children}
            </Grid>
        </Grid>
    );
}





