
import React from 'react';
import {Provider} from 'react-redux';
//import {ConnectedRouter} from 'react-router-redux';
import {history, store, persistor} from './setup';
import { PersistGate } from 'redux-persist/integration/react'

import {Router, Route, Switch} from 'react-router-dom';
import {authRoutes, PrivateRoute} from 'react-devise';
import Container from '@material-ui/core/Container';

import NotFound from './NotFound'
import Picks from './Picks'
import SettingsPage2 from './SettingsPage2'
import CheckoutPage from './CheckoutPage'

import StripeSettingsPage2 from './StripeSettingsPage2'
import Header from './Header'
import FrequencySettings from './FrequencySettings'
import ShippingSettings from './ShippingSettings'
import PasswordSettings from './PasswordSettings'
import WelcomePage from "./WelcomePage";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ThankYouPage from "./ThankYouPage";
import StyleProfile from "./StyleProfile";
import HeaderTitle from "./HeaderTitle";
import Grid from "@material-ui/core/Grid";
import CancelSubscriptionPage from "./CancelSubsriptionPage"
import ShipmentSchedulePage from "./ShipmentSchedulePage";




const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#a36b7a',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

class App extends React.Component {


  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <ThemeProvider theme={theme}>
            <Container maxWidth="lg" style={{padding: 0}}>
              <Grid container>
                <Grid item md={2} xs={12}>
                  <Header />
                </Grid>
                <Grid item md={8} xs={12} style={{
                  backgroundColor: 'white',
                  minHeight: '100vh',
                }}>
                  <Switch>
                    <PrivateRoute exact path="/" component={WelcomePage} />
                    <PrivateRoute exact path="/preview" component={() => < Picks status={20} />}  />
                    <PrivateRoute exact path="/checkouts/" component={() => < Picks status={80} />} />
                    <PrivateRoute exact path="/checkout/:pick_id" component={CheckoutPage} />
                    <PrivateRoute exact path="/settings" component={SettingsPage2} />
                    <PrivateRoute exact path="/stripe_settings" component={StripeSettingsPage2} />
                    <PrivateRoute exact path="/frequency_settings" component={FrequencySettings} />
                    <PrivateRoute exact path="/shipping_settings" component={ShippingSettings} />
                    <PrivateRoute exact path="/password_settings" component={PasswordSettings} />
                    <PrivateRoute exact path="/thank_you" component={ThankYouPage} />
                    <PrivateRoute exact path="/style_profile" component={StyleProfile} />
                    <PrivateRoute exact path="/cancel_subscription" component={CancelSubscriptionPage} />
                    <PrivateRoute exact path="/shipment_schedule" component={ShipmentSchedulePage} />
                    {authRoutes({notFoundComponent: NotFound}) }
                    <Route component={NotFound} />
                  </Switch>
                </Grid>
              </Grid>
            </Container>
            </ThemeProvider>
          </Router>
        </PersistGate>
      </Provider>
    );
  }

}

export default App

