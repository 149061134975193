import React from "react";
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import TextField from "@material-ui/core/TextField/TextField";
import SurveyTitleComp from './SurveyTitleComp';


export default function SurveyMultipleChoiceComp({field, response, onSubmit, id}) {
    console.log('SurveyMultipleChoiceComp');
    console.log(response);
    console.log(field);

    const [other, setOther] = React.useState((response && response.other) ? response.other : null );
    const [checked, setChecked] = React.useState((response && response.choices && response.choices.refs) ? response.choices.refs : []);
    console.log(other);
    console.log(checked);

    const generateResponse = () => {
        let labels = field.properties.choices.
                           filter((choice)=>checked.indexOf(choice.ref)>-1).
                           map((choice)=>choice.label);
        let result = {
            "type": "choices",
            "field": {
                "ref": field.ref,
                "type": "multiple_choice"
            },
            "choices": {
                "refs": checked,
                "labels": labels
            }
        }
        if (other) result['choices']['other'] = other;
        return result;
    }

    const verifyResponse = () => {
        if (!(field.validations && field.validations.required)) return true;
        if (other || checked.length > 0) return true;
        return false;
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleOtherSelect = () => {
        //console.log('handleOtherSelect');
        if (other == null) setOther("")
    }

    const handleOtherDeselect = (event) => {
        //console.log('handleOtherDeselect');
        //console.log(event.target.checked);
        if (!event.target.checked) setOther(null);
    }

    const handleOtherFill = (event) => {
        //console.log('handleOtherFill');
        //console.log(event.target.value);
        setOther(event.target.value);
    }

    const handleBlur = (event) => {
        if (other == '') setOther(null);
    }

    const onSaveResponse = () => {
        onSubmit(generateResponse());
    }

    return (
        <SurveyTitleComp
            id={id}
            field={field}
            onSubmit={onSaveResponse}
            submitDisabled={!verifyResponse()}
        >
            <FormControl fullWidth>
                <List >
                    {field.properties.choices.map((choice,i) => {
                        const labelId = `checkbox-list-label-${i}`;
                        return (
                            <ListItem key={i} role={undefined} dense button onClick={handleToggle(choice.ref)}>
                                <ListItemIcon>
                                    <Checkbox
                                        id={`typeform_checkbox_${i}_id`}
                                        edge="start"
                                        checked={checked.indexOf(choice.ref) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        //inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={choice.label} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <CommentIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                    {
                        field.properties.allow_other_choice && (
                            <ListItem id="typeform_other_listitem_id" key={'other'} role={undefined} dense button onClick={handleOtherSelect}>
                                <ListItemIcon>
                                    <Checkbox
                                        id="typeform_other_checkbox"
                                        edge="start"
                                        checked={other != null}
                                        onChange={handleOtherDeselect}
                                        tabIndex={-1}
                                        disableRipple
                                        //inputProps={{ 'aria-labelledby': 'checkbox-list-label-other' }}
                                    />
                                </ListItemIcon>
                                {(other !== null)  && (
                                    <TextField
                                        id="typeform_other_textfield_id"
                                        value={other}
                                        onChange={handleOtherFill}
                                        onBlur={handleBlur}
                                        placeholder="Type your answer"
                                        fullWidth />)
                                }
                                {(other == null) && (
                                    <React.Fragment>
                                        <ListItemText primary="Other" />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="comments">
                                                <CommentIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </React.Fragment>)
                                }

                            </ListItem>
                        )
                    }
                </List>
            </FormControl>
        </SurveyTitleComp>
    )
}