import React from "react"
import PropTypes from "prop-types"

import PickedItemLiker from "./PickedItemLiker"
import DeliveredItemStatusPicker2 from "./DeliveredItemStatusPicker2"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {TextField} from '@material-ui/core';
import {ORDER_ON_PREVIEW_STATUS, ORDER_OUT_FOR_DELIVERY_STATUS} from "../dashboard/AdminConsts";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 200,
    backgroundSize: 'contain'
  },
  feedbackInput:{
    //width: 350
  },
}));

export default function PickedItem(props){
  const classes = useStyles();
  const picked_item = props.item;
  const item = picked_item.item;

    return (
      <React.Fragment>
        <Card className={classes.root}>
            <CardMedia
              image={item.thumb_link}
              title={item.name}
              className={classes.cover}
              onClick={props.onImageClick}
            />
          <div className={classes.details}>
            <CardContent>
              <Typography  variant="h6" >
                {item.name}
              </Typography>

              <Typography variant="h5" component="h2" >
                { props.pick_status == ORDER_OUT_FOR_DELIVERY_STATUS && (`$${item.price/100}`)}
              </Typography>
            </CardContent>

            <CardActions style={{paddingLeft: '16px'}}>
              { props.pick_status == ORDER_ON_PREVIEW_STATUS && (<PickedItemLiker item={picked_item} verify={props.verify}/>)}
              { props.pick_status == ORDER_OUT_FOR_DELIVERY_STATUS && (<DeliveredItemStatusPicker2 item={picked_item} verify={props.verify} />)}
            </CardActions>
            <CardActions >
            </CardActions>
          </div>
          
        </Card>

      </React.Fragment>
    );

}


//export default PickedItem
