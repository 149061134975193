import React, {Component} from "react";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {makeStyles} from "@material-ui/core/styles";


export default class AdminMessageDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
        };
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.state.open != nextProps.open) this.setState({open: nextProps.open});
    }

    handleSkipMessageClose = () => {
        if (this.props.onClose)
            this.props.onClose();
        else
            this.setState({open: false});
    }

    render() {
        return (
            <RawModal
                open={this.state.open}
                onClose={this.handleSkipMessageClose}
                onOkClick={this.handleSkipMessageClose}
                title={this.props.title}
                message={this.props.message}
                position={this.props.position}
            />

        )
    }

}

const useStyles = makeStyles({
    top: {
        alignItems: "flex-end"
    },
    center: {
        alignItems: "flex-end"
    },
    bottom: {
        alignItems: "flex-end"
    }
});

const RawModal = (props) => {
    const classes = useStyles();
    let position;
    if (props.position) {
        position = classes[props.position];
    } else {
        position = classes.center;
    }
    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{scrollPaper: position}}
    >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onOkClick} color="primary" autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog>)
}