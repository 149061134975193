import React from "react"
import PropTypes from "prop-types"

import {connect} from 'react-redux';
import {logout} from 'react-devise/lib/actions';

class SignOut extends React.Component {
  render () {
    const {currentUser, doLogout} = this.props;
    return (
      <React.Fragment>
         <a href="/" onClick={() => doLogout()}>Switch account</a> 
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doLogout: () => logout(dispatch)
  };
};

const SignOutContainer = connect(mapStateToProps, mapDispatchToProps)(SignOut);

export default SignOutContainer;
