import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const desktopScreenStyle = {
    backgroundColor: '#a36b7a',
    padding: "20px 0 20px 30px",
    marginBottom: '20px',
}

const desktopTextStyle = {
    color: 'white',
    fontWeight: 800,
    fontSize: '1.7rem',
}

const mobileScreenStyle = {
    backgroundColor: 'white',
    padding: "20px 0 20px 30px",
    marginBottom: '20px',
}

const mobileTextStyle = {
    color: '#a36b7a',
    fontWeight: 800,
    fontSize: '1.7rem',
}


function HeaderTitle(props){
    return (
        <React.Fragment>
            <Grid item xs={12} style={isWidthUp('md', props.width) ? desktopScreenStyle: mobileScreenStyle}>
                <Typography variant={'h4'} style={isWidthUp('md', props.width) ? desktopTextStyle: mobileTextStyle}>
                    {props.title}
                </Typography>
            </Grid>
        </React.Fragment>
    )
}

export default withWidth()(HeaderTitle)