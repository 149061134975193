import React from "react";
import {Grid} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SurveyComponentSelector from "./SurveyComponentSelector";
import {fetchWithJWT} from "../FetchWithJWT";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid item container xs={12}>
                    {children}
                </Grid>
            )}
        </div>
    );
}

export default function SurveyQuestionsOrder(props) {
    console.log('SurveyQuestionsOrder');
    console.log(props);
    const theme = useTheme();
    const [answers, setAnswers] = React.useState((props.responses  && props.responses.answers)? props.responses.answers : []);
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
    //console.log(answers);
    //console.log('orderedFields');
    //console.log(orderedFields);

    const orderQuestions = (fields) => {
        let orderedQuestions = []
        fields.forEach((field) => {
            orderedQuestions.push(field);
            if (field['type'] == 'group'){
                orderedQuestions = orderedQuestions.concat(orderQuestions(field['properties']['fields']));
            }
        })
        return orderedQuestions;
    }

    const removeSkipQuestions = (fields) => {
        let newFields = [...fields];
        props.form.logic.forEach((l)=>{
            let firstPoint = newFields.findIndex((f => l.ref == f.ref));
            console.log(l);
            if ((firstPoint > -1) &&
                (l.type == 'field') &&
                (l.actions?.length == 1) &&
                (l.actions[0]?.action == 'jump') &&
                (l.actions[0].condition?.op == 'always') &&
                (l.actions[0].details?.to?.value)
            ){
                let lastPoint = -1;
                if (l.actions[0].details.to.type == 'field') {
                    let jumpToRef = l.actions[0].details.to.value
                    lastPoint = newFields.findIndex((f => jumpToRef == f.ref));
                }
                if (lastPoint == -1) {
                    lastPoint = newFields.length;
                }
                console.log(`Remove items from ${firstPoint} to ${lastPoint}`);
                newFields = newFields.filter((a,i)=> i<=firstPoint || i >= lastPoint);
            } else {
                console.log('Error');
                console.log(l);
            }

        });
        return newFields;
    }

    const correctOrderQuestions = (fields) => {
        let flatFields = orderQuestions(fields);
        let orderedFields = removeSkipQuestions(flatFields);
        let savePage = {
            "type": "save_page",
            "title": "Would you like to save your new survey?",
            "properties": {
                "button_text": "Save",
            }
        };
        let finishPage = {
            "type": "finish_page",
            "title": "Your new survey is saved!",
            "properties": {
                "hide_button": true
            }
        };
        orderedFields.push(savePage);
        orderedFields.push(finishPage);

        return orderedFields;
    }

    const [orderedFields, setOrderedFields] = React.useState(correctOrderQuestions(props.form.fields));

    const findUserResponse = (responseRef) => {
        let response = answers.filter((a) => {
            return a.field.ref == responseRef
        })
        if (response && response.length == 1) {
            return response[0];
        }
        return null;
    }

    const previousQuestion = () => {
        if (currentQuestionIndex > 0)
            setCurrentQuestionIndex(currentQuestionIndex-1);
    }

    const nextQuestion = () => {
        console.log('nextQuestion');
        if (currentQuestionIndex < orderedFields.length-1)
            setCurrentQuestionIndex(currentQuestionIndex+1);
    }

    const saveAnswers = () => {
        console.log('saveAnswers');
        let data = {
            answers: answers,
            landed_at: props.landed_at,
            typeform_form_id: props.typeform_form_id,
        };
        let body = JSON.stringify(data);
        fetchWithJWT(`/account/survey`,'POST', {body: body})
            .catch((error) => {alert(error)})
            .then((data) => {
                if (data && data.status == 'ok') nextQuestion();
                if (data && data.status == 'error') alert(data.error);
            });
    }

    const updateQuestionValue = (newResponse) => {
        console.log('updateQuestionValue');
        console.log(newResponse);
        if ((newResponse == null) ||
            (newResponse.type == 'save_page') ||
            (newResponse.type == 'finish_page')) return;
        let index = answers.findIndex((r => r.field.ref == newResponse.field.ref));
        console.log('index = ' + index);
        if (index > -1) {
            answers[index] = newResponse;
        } else {
            answers.push(newResponse);
        }
        setAnswers([...answers]);
    }

    const onSubmit = (newResponse) =>{
        console.log('onSubmit');
        console.log(newResponse);
        if (newResponse && (newResponse['type'] == 'save_page')) {
            saveAnswers();
        } else {
            updateQuestionValue(newResponse);
            nextQuestion();
        }
    }

    const handleChangeIndex = (index) => {
        //setValue(index);
        setCurrentQuestionIndex(index);
    };

    return (
        <Grid container>
            {true && (
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-start">
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={previousQuestion}>&lt;</Button>
                            {false && <Button variant="outlined" color="secondary" onClick={nextQuestion}>&gt;</Button>}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item container xs={12}>
                <div >
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={currentQuestionIndex}
                        onChangeIndex={handleChangeIndex}
                    >
                        {orderedFields && orderedFields.map((field, i) =>
                            <TabPanel key={i} value={currentQuestionIndex} index={i} dir={theme.direction}>
                                <Grid item container xs={12} style={{padding: 100}}>
                                    ({i}){field.ref}<br/>
                                    <SurveyComponentSelector field={field}
                                                             response={findUserResponse(field.ref)}
                                                             onSubmit={onSubmit}/>
                                </Grid>
                            </TabPanel>
                        )}
                    </SwipeableViews>
                </div>
            </Grid>
        </Grid>
    )
}