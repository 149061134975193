import React from "react";
import {Grid} from "@material-ui/core";
import SurveyTextFieldComp from "./SurveyTextFieldComp";
import SurveyGroupComp from "./SurveyGroupComp";
import SurveyDropdownComp from  "./SurveyDropdownComp";
import SurveyMultipleChoiceComp from "./SurveyMultipleChoiceComp";
import SurveySingleAndOtherChoiceComp from "./SurveySingleAndOtherChoiceComp";
import SurveyTitleComp from "./SurveyTitleComp";


export default function SurveyComponentSelector(props) {
    //const [messageWindow, setMessageWindow] = React.useState({open: false, foundIcon: true})
    //console.log('SurveyComponentSelector');
    //console.log(props);
    let {field} = {...props};
    let resultComponent;
    switch (field.type) {
        case 'group':
            resultComponent = (<SurveyGroupComp {...props} id="typeform_group_comp_id"/>);
            break;
        case 'short_text':
        case 'long_text':
            resultComponent = (<SurveyTextFieldComp {...props} id="typeform_text_comp_id"/>);
            break;
        case 'dropdown':
            resultComponent = (<SurveyDropdownComp {...props} id="typeform_dropdown_comp_id"/>);
            break;
        case 'multiple_choice':
            if (field.properties.allow_multiple_selection) {
                resultComponent = (<SurveyMultipleChoiceComp {...props} id="typeform_multiple_choice_comp_id"/>);
            } else {
                resultComponent = (<SurveySingleAndOtherChoiceComp {...props} id="typeform_single_choice_comp_id"/>);
            }
            break;
        case 'save_page':
            resultComponent = (<SurveyTitleComp {...props} onSubmit={()=>props.onSubmit(props.field)} id="typeform_save_page_comp_id"/>);
            break;
        case 'finish_page':
            resultComponent = (<SurveyTitleComp {...props} onSubmit={()=>props.onSubmit(null)} id="typeform_finish_page_comp_id"/>);
            break;
        default:
            resultComponent = (<Grid item xs={12}>{`Error type of error: ${field.type}.`}</Grid>);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {resultComponent}
            </Grid>
        </Grid>)
}
