import React from "react"
import PropTypes from "prop-types"

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ViewListIcon from '@material-ui/icons/ViewList';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';

import {history} from './setup';
import {logout} from "react-devise/lib/actions";
import {connect} from "react-redux";
import {withAuth} from "react-devise";
import {fetchWithJWT, postPageUploadEvent, userLogined} from "./FetchWithJWT";
import HangerIcon from "./HangerIcon";

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CustomDrawer from "./CutomDrawer";


const headerStyle = {
	flexDirection: 'column',
	justifyContent: 'flex-start',
	marginTop: '50px',
	backgroundColor: 'inherit',
}

const buttonStyle= {
	padding: '20px 12px 20px',
	minHeight: '77px',
}

const disabledButtonStyle = {
	color: 'rgb(0 0 0 / 25%)',
	padding: '20px 12px 20px',
	minHeight: '77px',
}

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		  value: '/',
          checkout_disable: false,
	      preview_disable: false
		};
	}

  componentDidMount() {
	  this.checkMenuItemEnabled()
  }

  checkMenuItemEnabled = () => {
	  //console.log('HeaderRender-checkMenuItemEnabled')
	  if (userLogined()) {
		  fetchWithJWT(`/account/has_order_for`)
			  .then((data) => {
			  	  if ((this.state.checkout_disable == data['has_order_for_checkout']) ||
					  (this.state.preview_disable == data['has_order_for_preview'])	) {
					  this.setState({
						  checkout_disable: !data['has_order_for_checkout'],
						  preview_disable: !data['has_order_for_preview']
					  })
				  }
			  });
	  }
  }

  onMenuChange = (event, newValue) =>{
	  this.setState({value: newValue});
	  postPageUploadEvent();
	  history.push(newValue)
  }

  render () {
  	let {currentUser} = this.props
	//console.log('HeaderRender')
	//console.log(this.state)
	this.checkMenuItemEnabled()

	let header = (
		<BottomNavigation
			value={this.state.value}
			onChange={this.onMenuChange}
			showLabels
			style={headerStyle}
		>
			<BottomNavigationAction label="Account"
									value="/settings"
									icon={<AccountCircleIcon />}
									style={buttonStyle}/>
			<BottomNavigationAction label="Order Preview"
									//disabled={this.state.preview_disable}
									//style={ this.state.preview_disable ? disabledButtonStyle : buttonStyle}
									value="/preview"
									icon={<ViewListIcon />} />
			<BottomNavigationAction label="Checkout"
									disabled={this.state.checkout_disable}
									style={ this.state.checkout_disable ? disabledButtonStyle : buttonStyle}
									value="/checkouts"
									icon={<ShoppingCartIcon />} />

			<BottomNavigationAction label="Shipment Schedule"
									value="/shipment_schedule"
									icon={<DateRangeIcon/>}
									style={buttonStyle}
									/>
			<BottomNavigationAction label="Style Profile"
									value="/style_profile"
									icon={<HangerIcon/>}
									style={buttonStyle}
									/>
		</BottomNavigation>)

	if (isWidthUp('md', this.props.width)) {
	} else {
		header = (<React.Fragment>
			<CustomDrawer>
				{header}
			</CustomDrawer>
		</React.Fragment>)
	}

    return (
      <React.Fragment>
		  { currentUser.isLoggedIn && header}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
	return {
		currentUser: state.currentUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		doLogout: () => logout(dispatch)
	};
};

export default connect(mapStateToProps,mapDispatchToProps)(withAuth(withWidth()(Header)));

