import React from "react"
import {Button, TextField} from "@material-ui/core";

export const renderInput2 = ({input, label, type, meta: {touched, error, warning}}) => (
    <div>
        <label>{label}</label>
        <div>
            <input {...input} placeholder={label} type={type}/>
            {touched && ((error && <span {...fieldErrorProps}>{error}</span>) || (warning && <span {...fieldWarningProps}>{warning}</span>))}
        </div>
    </div>
);
export const SubmitButton2 = ({label, disabled}) => (
    <input
        type="submit"
        value={label}
        disabled={disabled}
    />
);

export const renderInput = ({input, label, type, meta: {touched, error, warning}}) => (
    <div>
    <TextField
        variant="outlined"
        label={label}
        fullWidth={true}
        helperText={error}
        error={touched && error}
        inputProps={{...input, type: type}}
    />

    </div>
);

export const SubmitButton = ({label, disabled}) => (
    <Button type="submit"  fullWidth={true} variant="contained" color="secondary">
        {label}
    </Button>
);