import React from "react"
import ReactHtmlParser from 'react-html-parser';

//export default function HangerIcon(){
//    let str = '<svg xmlns="http://www.w3.org/2000/svg" class="MuiSvgIcon-root" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.07em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 17 16"><path d="M16.542 11.375S9.841 6.953 9.579 6.846V5.96c.827-.233 1.38-1.005 1.38-1.915c0-1.098-.885-1.99-1.971-1.99a1.982 1.982 0 0 0-1.969 1.99c0 .293.237.529.531.529c.293 0 .426-.342.426-.635c0-.512.545-.981 1.044-.981c.5 0 1.021.575 1.021 1.087c0 .511-.554.928-1.054.928c-.294 0-.473.237-.473.529v1.322c-.198.073-6.995 4.551-6.995 4.551c-.503.513-1.188 2.582 1.206 2.582h12.581c2.194 0 1.717-2.094 1.236-2.582zm-.985 1.646H2.456c-.671 0-.652-.756-.347-1.086l6.349-4.016c.343-.182.774-.206 1.113-.004l6.37 4.021c.307.33.231 1.085-.384 1.085z" fill="#626262" fill-rule="evenodd"/></svg>';
//    return (  <React.Fragment>{ReactHtmlParser(str)}</React.Fragment>)
//}

export default function HangerIcon(){
    return (
        <React.Fragment>
          <svg xmlns="http://www.w3.org/2000/svg"
               fill="#626262"
               fillRule="evenodd"
               className="MuiSvgIcon-root"
               xmlnsXlink="http://www.w3.org/1999/xlink"
               aria-hidden="true"
               focusable="false"
               width="1.07em"
               height="1em"
               style={{'msTransform': 'rotate(360deg)', 'WebkitTransform': 'rotate(360deg)', 'transform': 'rotate(360deg)'}}
               preserveAspectRatio="xMidYMid meet"
               viewBox="0 0 17 16">
              <path d="M16.542 11.375S9.841 6.953 9.579 6.846V5.96c.827-.233 1.38-1.005 1.38-1.915c0-1.098-.885-1.99-1.971-1.99a1.982 1.982 0 0 0-1.969 1.99c0 .293.237.529.531.529c.293 0 .426-.342.426-.635c0-.512.545-.981 1.044-.981c.5 0 1.021.575 1.021 1.087c0 .511-.554.928-1.054.928c-.294 0-.473.237-.473.529v1.322c-.198.073-6.995 4.551-6.995 4.551c-.503.513-1.188 2.582 1.206 2.582h12.581c2.194 0 1.717-2.094 1.236-2.582zm-.985 1.646H2.456c-.671 0-.652-.756-.347-1.086l6.349-4.016c.343-.182.774-.206 1.113-.004l6.37 4.021c.307.33.231 1.085-.384 1.085z" />
          </svg>
       </React.Fragment>)
}

