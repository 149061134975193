import React from 'react';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
    list: {
        width: 180,
    },
});

export default function CustomDrawer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        open: false
    });

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, open: open });
    };

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {props.children}
        </div>
    );

    return (
        <div style={{backgroundColor: 'white'}}>
            <React.Fragment >

                <IconButton onClick={toggleDrawer( true)} style={{backgroundColor: '#a36b7a',borderRadius: 0, color: 'white'}}>
                    <MenuIcon fontSize="large" />
                </IconButton>

                <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
                    {list()}
                </Drawer>
            </React.Fragment>

        </div>
    );
}
