import {Grid} from "@material-ui/core";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Collapse from "@material-ui/core/Collapse/Collapse";
import SurveyQuestionsOrder from "./SurveyQuestionsOrder";
import {fetchWithJWT} from "../FetchWithJWT";

const grayColor2 = 'rgba(0, 0, 0, 0.54)';
const grayColor3 = '#dadada'
const grayColor4 = '#f6f7f7';
const text2Style = {fontWeight: 'bold', color: grayColor2, textTransform: 'capitalize'};
const borderStyle = {border: '1px solid '+ grayColor3};

export default function UserSurvey(props) {
    const [survey, setSurvey] = React.useState(null);
    const [expanded, setExtended] = React.useState(false);
    useEffect(() => {
    }, []);

    const onExtendClick = () => {
        if (!expanded && !survey){
            fetchWithJWT(`/account/survey`)
                .then((data) => {
                    setSurvey(data.survey);
                });
        }
        setExtended(!expanded);
    }

    return (
        <Grid container style={borderStyle}>
            <Grid container item xs={12} style={{backgroundColor: grayColor4, padding: '8px'}} alignItems={'center'}>
                <Grid item xs={10} md={11}>
                    <Typography style={text2Style}>
                        Typeform Survey
                    </Typography>
                </Grid>

                <Grid item xs={2} md={1}>
                    <IconButton onClick={onExtendClick} id="typeform_survey_extend_btn_id">
                        {!expanded && (<ArrowDownwardIcon />)}
                        {expanded && (<ArrowUpwardIcon />)}
                    </IconButton>
                </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" style={{marginLeft: 30}}>
                {survey && survey.form &&
                  <SurveyQuestionsOrder {...survey}/>
                }
            </Collapse>
        </Grid>
    )
}