import React from 'react';
import {Button, Grid, TextField, Typography} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import {fetchWithJWT} from "./FetchWithJWT";

class StatusPage extends React.Component {
    constructor(props) {
        super(props);
        this.noteRef = React.createRef();
    }

    handleOk = () => {
        window.history.back()
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity={this.props.severity}>{this.props.message}</Alert>
                </Grid>

                <Grid item xs={12} container justify={'center'} >
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={() => this.handleOk()}>OK</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default StatusPage