import React from "react";
import {fetchWithJWT, userLogined} from "./FetchWithJWT";
import {Button, Grid, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse/Collapse";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const grayColor1 = '#f6f7f7';
const grayColor2 = 'rgba(0, 0, 0, 0.54)';
const grayColor3 = '#dadada';

const borderStyle = {border: '1px solid '+grayColor3};

const textInputBox1Style1 = {height:'270px', border: '1px solid '+grayColor3};
const textInputBox1Style2 = {height:'270px', border: '1px solid '+grayColor3, backgroundColor: grayColor1 };

const title1Style = {backgroundColor: grayColor1, padding: '8px'};
const title2Style = { padding: '8px'};
const text1Style = {fontWeight: 'bold', color: grayColor2};
const text2Style = {fontWeight: 'bold', color: grayColor2};
const text3Style = {color: grayColor2};
const text4Style = {color: grayColor2, fontSize: '0.8rem'};

const margin8Style = {margin: '8px'};
const margin20Style = {margin: '20px'};

const messagesBoxStyle = {height:'270px', maxHeight: '270px', overflow: 'auto', padding: '0 6px'};

const messageStyle = {backgroundColor: '#dadada', marginBottom: '8px', padding: '10px'};

export default class CustomerRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            saved: false,
            error: null,
            expanded: false,
            text: '',
        };
        this.textRef = React.createRef();
    }

    componentWillMount(){
        fetchWithJWT(`/messages`)
            .then((data) => {
                this.setState({messages: data});
            });
    }

    onSaveRequestClick = () => {
        let text = this.state.text;
        let body = JSON.stringify({message: {text: text}});
        fetchWithJWT(`messages`, 'POST', {body: body})
            .then((data)=>{
                if (data.status == 'created') {
                    this.setState({saved: true, error: null, messages: data.data, text: ''});
                } else {
                    this.setState({error: data.message});
                }
            })
    }

    onAddAnotherNoteClick = () => {
        this.setState({saved: false});
    }

    onDeleteIconClick = (id) => {
        fetchWithJWT(`messages/${id}`, 'DELETE', )
            .then((data)=>{
                if (data.status === 'deleted') {
                    this.setState({messages: data.data});
                }
            })
    }

    onExtendClick = () => {
        this.setState({expanded: !this.state.expanded});
    }

    onTextUpdate = (e) => {
        this.setState({text: e.target.value});
    }

    render() {
        //console.log(this.state)
        return (
            <Grid container style={borderStyle} >
                <Grid container item xs={12} style={title1Style} alignItems={'center'}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="body1" style={text1Style}>
                            Notes & Requests For Your Stylist
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={7}>
                        <Typography variant="body1" style={text4Style}>
                            Let your stylist know about dates for upcoming occasions, specific items you'd like to
                            receive or any other comments you think might be helpful to get your best shipment yet!
                        </Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <IconButton onClick={this.onExtendClick} id="customer_request_extend_btn_id">
                            {!this.state.expanded && (<ArrowDownwardIcon />)}
                            {this.state.expanded && (<ArrowUpwardIcon />)}
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={this.state.expanded} timeout="auto" >
                        <Grid container item xs={12} style={margin8Style}>
                            <Grid container item md={6} xs={12}>
                                <Grid item xs={12} style={title2Style}>
                                    <Typography variant="body1" style={text2Style}>
                                        Current
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} style={this.state.saved ? textInputBox1Style2 : textInputBox1Style1}  >
                                    {!this.state.saved && (
                                        <React.Fragment>
                                            <Grid item xs={12} style={margin8Style}>
                                                <TextField
                                                    fullWidth={true}
                                                    multiline
                                                    rows={10}
                                                    value={this.state.text}
                                                    onChange={this.onTextUpdate}
                                                    inputRef={this.textRef}
                                                />
                                            </Grid>
                                            <Grid container item xs={12}  justify="flex-end" style={margin8Style}>
                                                <Grid item>
                                                    <Button variant="contained"
                                                            color="secondary"
                                                            onClick={this.onSaveRequestClick}
                                                            disabled={this.state.text == ''}
                                                    >Save</Button>

                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    {this.state.saved && (
                                        <React.Fragment>
                                            <Grid item xs={12} style={margin20Style}>
                                                <Typography align="center" variant="body1" style={text3Style}>
                                                    Thanks!<br/>
                                                    Your note has been shared with your stylist!
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12}  justify="center" style={margin20Style}>
                                                <Grid item>
                                                    <Button variant="contained"
                                                            color="secondary"
                                                            onClick={this.onAddAnotherNoteClick}>Add another note</Button>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12}>
                                <Grid item xs={12} style={title2Style}>
                                    <Typography variant="body1" style={text2Style}>
                                        Past
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} style={messagesBoxStyle} alignItems={'flex-start'} alignContent={'flex-start'}>
                                    {this.state.messages.map((m, i) => (
                                        <Grid key={i} item container xs={12} style={messageStyle} >
                                            <Grid item xs={11}>
                                                <Typography variant="body1" style={text3Style}>
                                                    {m.text}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton aria-label={''} onClick={()=>this.onDeleteIconClick(m.id)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>

                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        );
    }

}