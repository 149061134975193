import React, {Component} from "react"
import PropTypes from "prop-types"

import {Button, Grid, Snackbar, TextField} from '@material-ui/core';
import {Container} from '@material-ui/core';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import '../styles/common.css';
//import Recaptcha from 'react-google-invisible-recaptcha';
import ReCAPTCHA from "react-google-recaptcha";

import {
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    FormControl,
} from '@material-ui/core';

import {fetchWithJWT} from "./FetchWithJWT";
import Alert from "@material-ui/lab/Alert/Alert";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#a36b7a',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});


class GiftPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            shipping: null,
            status: 'init',
            error: null,
            gift: {
              recipient_name: '',
              recipient_email: '',
              greeting_text: '',
              giver_name: '',
              giver_email: '',
              gift_amount: '200',
              delivery_date: new Date(),
            },
            invalid:{
              recipient_name: false,
              recipient_email: false,
              greeting_text: false,
              giver_name: false,
              giver_email: false,
            },
            submit_btn_disable: false,
        };
        this._refRecaptcha = React.createRef();
    }


    handleDataChange = (event) => {
      //console.log(event.target)
      let key = event.target.name 
      let value = {gift: this.state.gift, invalid: this.state.invalid}
      value.gift[key] = event.target.value

      if (this.state.invalid[key]){
        if (this.validateValue(key)){
          value.invalid[key] = false
        }
      }
      this.setState(value)
    };

    updateDeliveryDate = (date) => {
      let value = {gift: this.state.gift};
      value.gift.delivery_date = date;
      this.setState(value);
    };

    validateValue = (key) => {
      let value = this.state.gift[key]
      if (value.length < 1){
        return false
      }
      if (key.indexOf('_email') >= 0){
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }
      return true
    }

    validateFields = () => {
      let invalid_fields = {
        recipient_name: false,
        recipient_email: false,
        giver_name: false,
        giver_email: false,
      }

      Object.keys(invalid_fields).forEach((k) => {
        if (!this.validateValue(k)){
         invalid_fields[k] = true
        }
      })
      this.setState({invalid: invalid_fields})
      return Object.values(invalid_fields).every((v) => !v)
    }

    handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      if(!this.validateFields()){
        return
      }

      const {stripe, elements} = this.props;
      if (!stripe || !elements) {
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      this.setState({submit_btn_disable: true});
      console.log("refRecaptcha");
      const refRecaptcha = this._refRecaptcha;
      console.log(refRecaptcha);
      console.log(refRecaptcha.current);

      const recaptchaToken = await refRecaptcha.current.executeAsync();
          //.then(value => {
          //console.log("executeAsync promise - Captcha value:", value);
      //});
      console.log(recaptchaToken);

      //console.log(recaptchaToken.asd());


      const {source, error} = await stripe.createSource(cardElement, {type: 'card'}) //stripe.createPaymentMethod({
      //  type: 'card',
      //  card: cardElement,
      //});

      if (error) {
        console.log('[error]', error);
        this.setState({status: 'error', error: JSON.stringify(error)})
      } else {
        console.log('[Source]', source);
        let gift = this.state.gift;
        let day = gift.delivery_date;
        gift.delivery_date = day.getFullYear()+"-"+(day.getMonth()+1)+"-"+day.getDate();
        let body = JSON.stringify({source: source, gift: gift, 'g-recaptcha-response': recaptchaToken})
        fetchWithJWT("gifts", 'POST', {body: body})
          .then((data)=>{
            console.log(data)
            if (data.status == 'ok'){
              this.setState({status: 'success'})
            } else {
              this.setState({status: 'error', error: data.message})
            }
        }) 
      }
    }

    handleChange1 = value => {
        console.log("onChange prop - Captcha value:", value);
    };
    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!", recaptchaLoaded: true });
        console.log("scriptLoad - reCaptcha Ref-", this._refRecaptcha);
    };

    render() {
        console.log('PageForGiftGivers render')

        let shipping = this.state.shipping
        const {stripe} = this.props;
        return (
          <Container maxWidth="sm">
            <ThemeProvider theme={theme}>
                <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    //theme="dark"
                    size="invisible"
                    ref={this._refRecaptcha}
                    sitekey={"6LeVrCsjAAAAAHVqDrMuETHhyfQuy8Eq8ONCimal"}
                    //onChange={this.handleChange1}
                    //asyncScriptOnLoad={this.asyncScriptOnLoad}
                />
                <Grid container spacing={4} justify={'center'}>
                    { this.state.status == 'success' && (
                        <React.Fragment>
                          <Grid item xs={12}>
                              <Alert severity="success">"You successfully bought a gift card"</Alert>
                          </Grid>
                        </React.Fragment>
                    )}
                    { this.state.status == 'error' && (
                        <React.Fragment>
                          <Grid item xs={12}>
                              <Alert severity="error">{this.state.error}</Alert>
                          </Grid>
                          <Grid item xs={12} container justify={'center'} >
                              <Grid item>
                                  <Button variant="contained" color="secondary" onClick={() => this.setState({status: 'init', submit_btn_disable: false})}>Back</Button>
                              </Grid>
                          </Grid>
                        </React.Fragment>
                    )}

                    { this.state.status == 'init' && (
                        <React.Fragment>
                            <Grid item xs={12} container justify={'flex-start'}>
                                <Grid item >
                                    <Typography  variant="h5" component="h5" style={{fontWeight: 'bold'}}>
                                      Step 1 - Gift Details
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                                <TextField
                                    variant="outlined"
                                    label="To"
                                    fullWidth={true}
                                    name="recipient_name"
                                    value={this.state.gift.recipient_name}
                                    onChange={this.handleDataChange}
                                    error={this.state.invalid.recipient_name}
                                    helperText={this.state.invalid.recipient_name ? 'Please, fill the field': ''}
                                />
                            </Grid>

                            <Grid item xs={12} container>
                                <TextField
                                    inputRef={this.line1Ref}
                                    variant="outlined"
                                    label="Email"
                                    fullWidth={true}
                                    name="recipient_email"
                                    value={this.state.gift.recipient_email}
                                    onChange={this.handleDataChange}
                                    error={this.state.invalid.recipient_email}
                                    helperText={this.state.invalid.recipient_email ? 'Please, fill the email address correctly': ''}
                                />
                            </Grid>

                            <Grid item xs={12} container>
                                <TextField
                                  id="filled-multiline-static"
                                  label="Add a message"
                                  multiline
                                  rows={5}
                                  placeholder="Example: Happy Birthday! Love, Jane"
                                  fullWidth={true}
                                  variant="outlined"
                                  name="greeting_text"
                                  value={this.state.gift.greeting_text}
                                  onChange={this.handleDataChange}
                                  helperText="We’ll include it in the email to your recipient"
                                />
                            </Grid>

                            <Grid item xs={12} container>
                                <TextField
                                    inputRef={this.line2Ref}
                                    variant="outlined"
                                    label="From"
                                    fullWidth={true}
                                    name="giver_name"
                                    value={this.state.gift.giver_name}
                                    onChange={this.handleDataChange}
                                    error={this.state.invalid.giver_name}
                                    helperText={this.state.invalid.giver_name ? 'Please, fill the field': ''}
                                />
                            </Grid>

                            <Grid item xs={12} container>
                                <TextField
                                    inputRef={this.line2Ref}
                                    variant="outlined"
                                    label="Email (for confirmation message)"
                                    fullWidth={true}
                                    name="giver_email"
                                    value={this.state.gift.giver_email}
                                    onChange={this.handleDataChange}
                                    error={this.state.invalid.giver_email}
                                    helperText={this.state.invalid.giver_email ? 'Please, fill the email address correctly': ''}
                                />
                            </Grid>

                            <Grid item xs={12} container justify="flex-start">
                                <Grid item >
                                    <Typography  variant="h5" component="h5" style={{fontWeight: 'bold'}}>
                                      Step 2 - Gift Card Value
                                    </Typography>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} container>
                                <FormControl component="fieldset">
                                  <RadioGroup row style={{justifyContent: "center"}} aria-label="position" name="gift_amount"  value={this.state.gift.gift_amount} onChange={this.handleDataChange}>
                                    {
                                        [25,50, 75, 100, 150, 200, 250, 300, 400, 500].map((item, index) => {
                                            return(
                                                <FormControlLabel
                                                  key={index}
                                                  value={""+item}
                                                  control={<Radio color="primary" />}
                                                  label={"$"+item}
                                                  labelPlacement="top"
                                                  
                                                />)
                                        })
                                    }
                                  </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} container justify={'flex-start'} >
                                <Grid item >
                                    <Typography  variant="h5" component="h5" style={{fontWeight: 'bold'}}>
                                      Step 3 - Delivery Details
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    minDate={Date()}
                                    id="date-picker-inline"
                                    label="Choose email delivery date"
                                    value={this.state.gift.delivery_date}
                                    onChange={this.updateDeliveryDate}
                                    fullWidth={true}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>  
                            </Grid>

                            <Grid item xs={12} container justify={'flex-start'}>
                                <Grid item >
                                    <Typography  variant="h5" component="h5" style={{fontWeight: 'bold'}}>
                                      Step 4 - Payment Details
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container justify={"flex-end"}>
                              <div className="StripeCardUpdate" style={{width: '100%'}}>
                                <form onSubmit={this.handleSubmit}>
                                  
                                  <CardElement
                                    options={{
                                      style: {
                                        base: {
                                          fontSize: '16px',
                                          color: '#424770',
                                          '::placeholder': {
                                            color: '#aab7c4',
                                          },
                                        },
                                        invalid: {
                                          color: '#9e2146',
                                        },
                                      },
                                    }}
                                  />
                                  <br/>
                                  <Button type="submit"
                                          variant="contained"
                                          color="secondary"
                                          disabled={!stripe || this.state.submit_btn_disable}>
                                      Pay for gift card
                                  </Button>
                                </form>
                              </div>  
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </ThemeProvider>
          </Container>    
        );
    }
};




const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <GiftPage elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

const stripePromise = loadStripe(window.stripeKey);

const PageForGiftGivers = () => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );
};




export default PageForGiftGivers
