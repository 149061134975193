

//Order statuses
export const ORDER_DELETED_STATUS  = -100
export const ORDER_HIDDEN_STATUS = 0
export const ORDER_ON_PREVIEW_STATUS = 20
export const ORDER_CONFIRMED_STATUS = 40
export const ORDER_OUT_FOR_DELIVERY_STATUS = 80
export const ORDER_FINISHED_STATUS = 100

export const ORDER_STATUS_LABELS = {
    [ORDER_DELETED_STATUS]: "deleted",
    [ORDER_HIDDEN_STATUS]:  "hidden",
    [ORDER_ON_PREVIEW_STATUS]:  "on_preview",
    [ORDER_CONFIRMED_STATUS]:  "confirmed",
    [ORDER_OUT_FOR_DELIVERY_STATUS]: "out_for_delivery",
    [ORDER_FINISHED_STATUS]: "finished",
}

//Selected Items
export const SELECTED_ITEM_DELETED_BY_SUPERVISOR_STATUS = -50
export const SELECTED_ITEM_UNDEFINED_STATUS = 0
export const SELECTED_ITEM_DISLIKE_STATUS = -10
export const SELECTED_ITEM_LIKE_STATUS = 10

export const SELECTED_ITEM_RETURNING_STATUS = -20
export const SELECTED_ITEM_EXCHANGING_STATUS = 50
export const SELECTED_ITEM_LOST_STATUS = 60
export const SELECTED_ITEM_KEEPING_STATUS = 100

/*const SELECTED_ITEM_STATUS_DISLIKE = -10
const SELECTED_ITEM_STATUS_LIKE = 10

const SELECTED_ITEM_STATUS_KEEPING = 100
const SELECTED_ITEM_STATUS_RETURNING = -20
const SELECTED_ITEM_STATUS_EXCHANGING = 50*/


export const SELECTED_ITEM_STATUS_LABELS = {
    [SELECTED_ITEM_UNDEFINED_STATUS]: "undefined",
    [SELECTED_ITEM_DISLIKE_STATUS]:  "dislike",
    [SELECTED_ITEM_LIKE_STATUS]:  "like",
    [SELECTED_ITEM_RETURNING_STATUS]: "returning",
    [SELECTED_ITEM_EXCHANGING_STATUS]: "exchanging",
    [SELECTED_ITEM_LOST_STATUS]: "lost",
    [SELECTED_ITEM_KEEPING_STATUS]: "keeping"
}

export const PAUSE_SUBSCRIPTION = -100
export const EVERY_MONTH_SUBSCRIPTION = 10
export const EVERY_OTHER_MONTH_SUBSCRIPTION = 20
export const EVERY_3_MONTH_SUBSCRIPTION = 30