import React from "react"
import PropTypes from "prop-types"

import Item from './Item'
import UserCard from './UserCard'
import StatusPage from './StatusPage'

import {fetchWithJWT, postPageUploadEvent} from "./FetchWithJWT"
import {Grid, Button, CircularProgress, Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CheckoutPromoField from './CheckoutPromoField'
import {checkoutOrder, viewPage} from "./Analitics";

class CheckoutPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {pick: {picked_items: null, price: 0}, status: 'show_progress'};
  }

  componentDidMount(){
    this.fetchOrder()
  }

  fetchOrder = () => {
      fetchWithJWT('/picks/'+this.props.match.params.pick_id)
          .then((data) => {this.setState({pick: data, status: 'init_checkout'})});
  }

  payButtonClick = () => {
    const order = {...this.state.pick};
    postPageUploadEvent();
    this.setState({status: 'show_progress'})
  	fetchWithJWT('/picks/'+this.props.match.params.pick_id+'/pay', 'POST')
      .then((data) => {
      	if (data['status'] == 'ok') {
      	   this.setState({status: 'success'});
           checkoutOrder(order);
      	} else {
      	   this.setState({status: 'error', error: data["message"]})
      	}
      });
  }

  returnButtonClick = () => {
    postPageUploadEvent();
    fetchWithJWT('/picks/'+this.props.match.params.pick_id+'/return', 'POST')
        .then((data) => {
            if (data['status'] == 'ok') {
                this.setState({status: 'return'})
                //checkoutOrder(this.state.pick);
            } else {
                this.setState({status: 'error', error: data["message"]})
            }
        });
  }

  handleAcceptPromo = () => {
    this.fetchOrder()
  }

  render () {
  	//console.log("CheckoutPage render")
  	//console.log(this.state)

    let keeped_items, returning_items
    if (this.state.pick.picked_items) {
        keeped_items = this.state.pick.picked_items
            .filter((item) => {
                return (item.status == 100)
            })
            .map((item) => {
                return (
                    <Grid key={item.id} item xs={12}>
                        <Item item={item.item}/>
                    </Grid>
                )
            })
        returning_items = this.state.pick.picked_items
            .filter((item) => {
                return (item.status != 100)
            })
            .map((item) => {
                return (
                    <Grid key={item.id} item xs={12}>
                        <Item item={item.item} returning/>
                    </Grid>
                )
            })
    }

    return (
      <Container maxWidth="sm">
      <Grid container spacing={2} justify={"center"} style={{marginTop: 10}}>
        { this.state.status == 'show_progress' && (
            <React.Fragment>
                <Grid item>
                    <CircularProgress color="secondary"/>
                </Grid>
                <Grid item>
                    <Typography  variant="h5" component="h5">
                        Processing payment...
                    </Typography>
                </Grid>

            </React.Fragment>    )}
        { this.state.status == 'init_checkout' && (
          <React.Fragment>

            { keeped_items && (keeped_items.length > 0) && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography  variant="h5" component="h5">
                            Keeping
                        </Typography>
                        <Divider />
                    </Grid>
                    {keeped_items}
                </React.Fragment>
            )}

              { returning_items && (returning_items.length > 0) && (
                  <React.Fragment>
                      <Grid item xs={12}>
                          <Typography  variant="h5" component="h5">
                              Returning
                          </Typography>
                          <Divider />
                      </Grid>
                      {returning_items}
                      { (keeped_items.length == 0) && (
                          <React.Fragment>
                              { false && (<Grid item xs={12} container >
                                  <Grid item container xs={12} md={7}></Grid>
                                  <Grid item xs={12} md={5} container justify={"flex-end"} spacing={3}>
                                      <Grid item xs={12} container justify={"space-between"} style={{color: 'red'}}>
                                          <Grid item>Styling fee:</Grid>
                                          <Grid item>-$25</Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>)}
                              <Grid item container justify={"flex-end"}>
                                <Button variant="contained" color="secondary" onClick={this.returnButtonClick}>I will return these items</Button>
                              </Grid>
                          </React.Fragment>
                      )}
                  </React.Fragment>
              )}


            {this.state.pick.picked_items && (keeped_items.length > 0) &&(
                <Grid item xs={12} container >
                    {!this.state.pick.discount && (
                        <Grid item container xs={12}>
                            <Grid item xs={12} style={{marginTop: '20px', marginBottom: '20px'}}>
                                <CheckoutPromoField handleAcceptPromo={this.handleAcceptPromo}/>
                            </Grid>
                        </Grid>

                    )}
                  <Grid item container xs={12} md={7}>
                      <Grid item style={{marginBottom: '20px'}}>
                        <UserCard/>
                      </Grid>
                  </Grid>

                  <Grid item xs={12} md={5} container justify={"flex-end"} spacing={3}>
                      <Grid item xs={12} container justify={"space-between"}>
                          <Grid item>Order Total:</Grid>
                          <Grid item> ${this.state.pick.price/100}</Grid>
                      </Grid>
                      {this.state.pick.discount && (
                        <Grid item xs={12} container justify={"space-between"} style={{color: 'red'}}>
                            <Grid item>Discount:</Grid>
                            <Grid item> -{this.state.pick.discount}%</Grid>
                        </Grid>
                      )}

                      {this.state.pick.styling_fee && (
                          <Grid item xs={12} container justify={"space-between"} style={{color: 'red'}}>
                              <Grid item>Styling fee:</Grid>
                              <Grid item>-${this.state.pick.styling_fee/100}</Grid>
                          </Grid>
                      )}
                      {this.state.pick.gift_amount && (
                          <Grid item xs={12} container justify={"space-between"} style={{color: 'rgb(163, 107, 122)'}}>
                              <Grid item>Gift:</Grid>
                              <Grid item>-${this.state.pick.gift_amount/100}</Grid>
                          </Grid>
                      )}
                      {this.state.pick.tax && (
                          <Grid item xs={12} container justify={"space-between"}>
                              <Grid item>Sales tax ({this.state.pick.tax}%):</Grid>
                              <Grid item>${this.state.pick.tax_amount/100}</Grid>
                          </Grid>
                      )}
                      {('total_price' in this.state.pick) && (
                          <Grid item xs={12} container justify={"space-between"}>
                              <Grid item>Total Price:</Grid>
                              <Grid item>${this.state.pick.total_price/100}</Grid>
                          </Grid>
                      )}

                    <Grid item >
                      <Button variant="contained" color="secondary" onClick={this.payButtonClick}>Place Order</Button>
                    </Grid>

                  </Grid>

            </Grid>)}
          </React.Fragment>)}
        { this.state.status == 'success' && (
            <StatusPage order_id={this.state.pick.id} severity="success" message="Successful payment" />
            )}
        { this.state.status == 'error' && (
            <StatusPage order_id={this.state.pick.id} severity="error" message={this.state.error} />
            )}
        { this.state.status == 'return' && (
              <StatusPage order_id={this.state.pick.id} severity="success" message="Thanks for sending back your returns. You will receive an email confirmation once we receive them." />
          )}
      </Grid>
      </Container>
    );
  }

}


export default CheckoutPage
