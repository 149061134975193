import React, {Component} from "react"
import PropTypes from "prop-types"

import {Button, Grid, Snackbar, TextField} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
//import Link from '@material-ui/core/Link';


import {fetchWithJWT} from "./FetchWithJWT";
import {history} from "./setup";
import {ORDER_OUT_FOR_DELIVERY_STATUS} from "../dashboard/AdminConsts";
import HeaderTitle from "./HeaderTitle";

const cancelSubscriptionReason = [
    "didn't_like_the_pieces_sent",
    "too_expensive",
    "don't_need_more_clothing",
    "other"
]

const PAUSE_SUBSCRIPTION = -100
const EVERY_MONTH_SUBSCRIPTION = 10
const EVERY_OTHER_MONTH_SUBSCRIPTION = 20
const EVERY_3_MONTH_SUBSCRIPTION = 30

const reasonLabelStyle = {
    padding: '10px 20px',
    backgroundColor: '#a36b7a',
    borderRadius: '4px',
    color: 'white',
    textAlign: 'center',
}

const reasonBtnStyle = {
    justifyContent: 'left',
    textTransform: 'capitalize',
}

export default  class CancelSubsriptionPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            reasons: [],
            reasonText: "",
            showDonePage: false,
        };
    }

    handleReasonBtnClick = (reason) => {
        let result = []
        if (this.state.reasons.includes(reason)) {
            result = this.state.reasons.filter((r) => r != reason)
        } else {
            result = this.state.reasons.concat(reason)
        }
        this.setState({reasons: result, reasonText: ""})
    }

    doneCancelSubscription = () => {
        let reason = this.state.reasons.join(', ')
        if (this.state.reasonText.length > 0) {
            reason = reason + ' : ' + this.state.reasonText
        }
        let body = JSON.stringify({frequency: PAUSE_SUBSCRIPTION, reason: reason})
        fetchWithJWT(`account/frequency`, 'PUT', {body: body})
            .then((data)=>{
                this.setState({showDonePage: true})
            })
    }

    handleTextReasonChange = (event) => {
        this.setState({reasonText: event.target.value})
    }

    reasonsIsFilled = () => {
        if (this.state.reasons && this.state.reasons.length > 0){
            if (this.state.reasons.includes('other')) {
                if (this.state.reasonText.length > 0) {
                    return true
                }
            } else {
                return true
            }
        }
        return false
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle title={'Cancel Subscription'}/>
                <br/><br/><br/><br/><br/>
                <Grid container spacing={2} justify={"center"} style={{padding: '0 10px'}}>
                    { !this.state.showDonePage && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h3" align={'center'} style={{color: '#173D54', fontSize: '2rem', fontWeight: 600}}>
                                We're so sorry to see you go...
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <div style={reasonLabelStyle}>
                                    Reason for cancellation
                                </div>
                            </Grid>

                            {cancelSubscriptionReason.map((r,i) => (
                                <Grid key={i} item xs={12}>
                                    <Button
                                        classes={reasonBtnStyle}
                                        onClick={()=>this.handleReasonBtnClick(r)}
                                        variant={ this.state.reasons.includes(r) ? 'contained' : 'outlined'}
                                        size="small"
                                        fullWidth={true}
                                    >{r.replaceAll('_', ' ')}</Button>
                                </Grid>
                            ))}
                            { this.state.reasons.includes("other") && (
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label=""
                                        fullWidth={true}
                                        multiline
                                        rows={3}
                                        value={this.state.reasonText}
                                        onChange={this.handleTextReasonChange}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} container justify={'center'}>
                            <Grid item>
                                <Button color="secondary"
                                        variant="contained"
                                        onClick={this.doneCancelSubscription}
                                        disabled={!this.reasonsIsFilled()}
                                >
                                    Done
                                </Button>
                            </Grid>
                        </Grid>
                   </React.Fragment> )}
                    { this.state.showDonePage && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="h3" align={'center'} style={{color: '#173D54', fontSize: '2rem', fontWeight: 600}}>
                                   Thanks for Trying Petite Ave
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" align={'center'} style={{color: '#173D54'}}>
                                    We will no longer send you shipments
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" align={'center'} style={{color: '#173D54'}}>
                                    If you change your mind, you can restart your subscription on the shipment schedule page
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    )}

                 </Grid>
            </React.Fragment>
        );
    }
};




