import React from 'react';
import {connect} from 'react-redux';
import {login} from "react-devise/lib/actions";
import {UnauthorizedError} from "react-devise/lib/errors";
import {Link, Redirect} from 'react-router-dom';
import {reduxForm, Field, SubmissionError} from 'redux-form';
import {required, email} from "react-devise/lib/views/validation";
import Alert from "@material-ui/lab/Alert/Alert";
import {Grid, TextField } from "@material-ui/core"
import Typography from "@material-ui/core/Typography";

const LoginForm = reduxForm({
    form: 'login'
})(({handleSubmit, valid, submitting, error, onSubmit, auth: {messages, viewPlugin: {renderInput, SubmitButton, Form, FormError}}}) => {
    const submit = data => {
        return onSubmit(data).catch(UnauthorizedError, () => {
            throw new SubmissionError({
                _error: messages.loginFailed
            });
        });
    };
    return (

        <Form onSubmit={handleSubmit(submit)}>
            <Grid container>
                <Grid item xs={1} md={2}/>
                <Grid item xs={10} md={8} container spacing={2} style={{marginTop: '100px'}}>

                    <Grid item xs={12} container justify={'center'}>
                        <Grid item>
                            <Typography  variant="h3" component="h3" align="center" style={{color: '#525F7F', fontWeight: 600}}>
                                WELCOME BACK!
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>

                        <Field
                            name="email"
                            component={renderInput}
                            label="Email"
                            validate={[required, email]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="password"
                            type="password"
                            component={renderInput}
                            label="Password"
                        />
                    </Grid>
                    <Grid item xs={12} container justify={"center"}>
                        <Grid item>
                            <SubmitButton
                                label={submitting ? 'Logging In...' : 'Log In'}
                                disabled={!valid || submitting}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justify={"center"}>
                        <Grid item>
                            <Link to="/users/password/new">
                                <Typography gutterBottom variant="h5" component="h2" style={{textDecoration: 'underline'}}>
                                    Forgot your password?
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>

    );
});

const Login = ({currentUser, doLogin, location: {state: {alert, from: {pathname: returnTo} = {}} = {}} = {}, ...rest} = {}) => {
    //console.log(location);
    const submit = data => {
        return doLogin(data);
    };
    if (currentUser.isLoggedIn) {
        return <Redirect to={'/'} />;
    }
    const {auth: {AuthLinks, viewPlugin: {View, Heading}}} = rest;
    return (
        <Grid container justify={"center"}>
            <LoginForm onSubmit={submit} {...rest} />
        </Grid>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    };
};

const mapDispatchToProps = dispatch => {
    return {
        doLogin: data => login(data, dispatch)
    };
};

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginPage
