import React, {Component} from "react"
import PropTypes from "prop-types"

import {Button, Grid, TextField} from '@material-ui/core';

import {fetchWithJWT} from "../app/FetchWithJWT";


export default class CheckoutPromoField extends Component{

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            message: "",
        };
        this.promoRef = React.createRef();
    }

    checkPromo = () => {
        if (this.promoRef.current.value.length > 0) {
            fetchWithJWT(`/account/apply_promo?search=${this.promoRef.current.value}`, 'POST',)
                .then((data) => {
                    if (data.status == 'ok') {
                        this.promoRef.current.value = ""
                        this.setState({error: false})
                        if (this.props.handleAcceptPromo) {
                            this.props.handleAcceptPromo()
                        }
                    } else {
                        this.setState({error: true, message: data.message})
                    }
                })
        } else {
            this.setState({error: true, message: "Please, enter a promo code"})
        }
    }

    render() {
        return (
            <Grid item container spacing={3}>
                <Grid item xs={8} md={10}>
                    <TextField
                        inputRef={this.promoRef}
                        variant="outlined"
                        label="Promo code"
                        fullWidth={true}
                        defaultValue=""
                        error={this.state.error}
                        helperText={this.state.error ? this.state.message: ''}
                    />
                </Grid>
                <Grid item container xs={4} md={2} style={{marginTop: '7px'}} justify={"flex-end"}>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={() => this.checkPromo()}>Apply</Button>
                    </Grid>
                </Grid>

            </Grid>
        );
    }
};

