import {compose, createStore, combineReducers, applyMiddleware} from 'redux';
import {routerReducer, routerMiddleware} from 'react-router-redux';
//import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from "history";
//import {persistStore, autoRehydrate} from 'redux-persist';
import {reducer as formReducer} from 'redux-form';
import injectTapEventPlugin from 'react-tap-event-plugin';

// import ReactDeviseMaterialUI from 'react-devise-material-ui';
import {initReactDevise, addAuthorizationHeaderToRequest} from 'react-devise';
import reactDeviseReducers from 'react-devise/lib/reducers';

//import ApolloClient, {createNetworkInterface} from 'apollo-client';
import LoginPage from './LoginPage'
import ResetPasswordPage from './ResetPasswordPage'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import {renderInput, SubmitButton} from './devisePlugin';


const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
      ...reactDeviseReducers,
      form: formReducer,
      router: routerReducer
    }))

const history = createBrowserHistory();

let store;

store = createStore(persistedReducer);

let persistor = persistStore(store);

const myCustomPlugin = {
    renderInput,
    SubmitButton
};
//console.log('initReactDevise')
let conf = initReactDevise({
    // other config
    routes: {
        login: {
            component: LoginPage
        },
        requestResetPassword: {
            component: ResetPasswordPage
        }
    },
    viewPlugins: [
        myCustomPlugin
    ]
});
//console.log(conf())

export {
  store,
  history,
  persistor 
};
