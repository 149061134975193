import React, {useState, useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"

import {BottomNavigation, BottomNavigationAction, Grid, Button, TextField} from '@material-ui/core';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { fetchWithJWT }  from "./FetchWithJWT"
import {
    SELECTED_ITEM_LIKE_STATUS, SELECTED_ITEM_DISLIKE_STATUS, ORDER_ON_PREVIEW_STATUS
} from "../dashboard/AdminConsts";
import Collapse from "@material-ui/core/Collapse/Collapse";

const useStylesForNavBtn = makeStyles({
    root: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        //padding: 0,
        margin: '5px 10px',
        minWidth: '10px',
        '&$selected': {
            color: 'white',
            borderColor: '#a36b7a',
            backgroundColor: '#a36b7a'
        }
    },
    selected: {},
    iconOnly: {paddingTop: '6px'}
});

const useStylesForReasonBtn = makeStyles({
    label: {
        justifyContent: 'left',
        textTransform: 'capitalize'
    }
});

const reasonLabelStyle = {
    padding: '10px 20px',
    backgroundColor: '#a36b7a',
    borderRadius: '4px',
    color: 'white',
    textAlign: 'center',
    marginRight: '40px',
}

const dislikeReasons = ['style', 'color', 'pattern', 'neckline', 'sleeves']

export default function PickedItemLiker(props) {
  const navBtnStyle = useStylesForNavBtn();
  const reasonBtnStyle = useStylesForReasonBtn();
  const [status, setStatus] = useState(props.item.status);

  let init_reasons = [];
  if (props.item.reasons.length > 0) {
      init_reasons = props.item.reasons.split(' ');
  }
  let init_feedback = props.item.preview_feedback;

  const [dislikeReason, setDislikeReason] = useState(init_reasons);
  const [feedback, setFeedback] = useState(init_feedback);
  useEffect(() => {
    verify(status, feedback, dislikeReason);
  }, []);

  const updateStatus = (event, newValue) => {
    if (newValue != status) {
        let body = JSON.stringify({picked_item: {status: parseInt(newValue), preview_feedback: '', reasons: ''}})
        fetchWithJWT(`picked_items/${props.item.id}`, 'PUT', {body: body})
            .then((item) => {
                setDislikeReason([]);
                setFeedback('');
                verify(newValue, '', []);
            })
            .catch((error)=>{
                setStatus(status);
            });
        setStatus(newValue);
    }
  }

  const verify = (status, feedback, reasons) => {
      if (status == SELECTED_ITEM_LIKE_STATUS) {
          props.verify(true);
      } else if (status == SELECTED_ITEM_DISLIKE_STATUS) {
        if (reasons.length > 0) {
            if (reasons.includes('other')) {
                //if (feedback && feedback.length > 0) {
                    props.verify(true);
                //} else {
                //    props.verify(false)
                //}
            } else {
                props.verify(true);
            }
        } else {
            props.verify(false);
        }
      } else {
          props.verify(false);
      }
  };

  const setReasons = (reason) => {
      let old_reasons = [...dislikeReason];
      let result = [];
      if (dislikeReason.includes(reason)) {
          result = dislikeReason.filter((r) => r != reason);
      } else {
          result = dislikeReason.concat(reason);
      }
      let body = JSON.stringify({picked_item: {reasons: result.join(' ')} });
      fetchWithJWT(`picked_items/${props.item.id}`, 'PUT', {body: body})
          .then((item)=>{verify(status, feedback, result)})
          .catch((error)=> {setDislikeReason(old_reasons)});
      setDislikeReason(result);
  }

  const handleReasonBtnClick = (reason) => {
      setReasons(reason)
  }

  const sendFeedback = (new_feedback) => {
      let oldFeedback = feedback;
      let body = JSON.stringify({picked_item: {preview_feedback: new_feedback} })
      fetchWithJWT(`picked_items/${props.item.id}`, 'PUT', {body: body})
        .then(() => verify(status, new_feedback, dislikeReason))
        .catch(() => setFeedback(oldFeedback));
      setFeedback(new_feedback);
  };

  const handleFeedbackChange = (event) => {
      sendFeedback(event.target.value);
  };

   return (
      <React.Fragment>
        <Grid container spacing={1}>
            <Grid item xs={8} container>
              <Grid item>Should we send this item?</Grid>
              <Grid item >
                <BottomNavigation
                  value={String(status)}
                  onChange={updateStatus}
                >
                  <BottomNavigationAction classes={navBtnStyle} value={String(SELECTED_ITEM_LIKE_STATUS)} label="" icon={<ThumbUpIcon />} />
                  <BottomNavigationAction classes={navBtnStyle} value={String(SELECTED_ITEM_DISLIKE_STATUS)} label="" icon={<ThumbDownIcon />} />
                </BottomNavigation>
              </Grid>

            </Grid>

            <Grid item xs={12} container>
                <Collapse in={status == String(SELECTED_ITEM_DISLIKE_STATUS)} timeout="auto">
                    <Grid item md={6} xs={12} container spacing={1}>
                        <Grid item xs={12}>
                            <div style={reasonLabelStyle}>
                                Reason?
                            </div>
                        </Grid>
                        {dislikeReasons.map((r,i) => (
                            <Grid key={i} item xs={12}>
                                <Button
                                    classes={reasonBtnStyle}
                                    onClick={()=>handleReasonBtnClick(r)}
                                    variant={ dislikeReason.includes(r) ? 'contained' : 'outlined'}
                                    size="small"
                                    fullWidth={true}
                                >{r}</Button>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                classes={reasonBtnStyle}
                                onClick={()=>handleReasonBtnClick('other')}
                                variant={ dislikeReason.includes('other')? 'contained' : 'outlined'}
                                size="small"
                                fullWidth={true}
                            >other</Button>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth={true}
                label={'Additional Feedback'}
                onChange={handleFeedbackChange}
                value={feedback}
                style={{maxWidth: '500px'}}
              />
            </Grid>
        </Grid>
      </React.Fragment>
  );
}
