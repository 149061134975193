import React, {useState} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field} from 'redux-form';
import {requestResetPassword, formAction} from 'react-devise/lib/actions';
import {required, email} from 'react-devise/lib/views/validation';
import {Grid, Button} from "@material-ui/core";

const RequestResetPasswordForm = reduxForm({
    form: 'requestResetPassword'
})(({handleSubmit, valid, submitting, submitSucceeded, error, onSubmit, auth: {messages, viewPlugin: {renderInput, SubmitButton, Form, FormError}}}) => {
    //console.log("error: " + error);
    console.log(`valid: ${valid}`);
    console.log(`submitting: ${submitting}`);
    const [resetSent, setResetSent] = useState(false);

    const handleResetPassword = () => {
        if (valid) {
            handleSubmit(formAction(onSubmit))();
            setResetSent(true);
        }
    }

    if (resetSent) {
        return(
            <Grid item container>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                    <p>
                        You should receive an email shortly
                        with a link to create a new password. If you don’t receive anything in the next few minutes,
                        please check your spam folder or reach out to support@petiteave.com for further assistance.
                    </p>
                </Grid>
            </Grid>)
    } else {
        return (
            <Grid item container spacing={4}>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={1} md={2}>
                </Grid>
                <Grid item xs={10} md={8}>
                    <Form onSubmit={handleResetPassword}>
                        <Field
                            name="email"
                            label="Email"
                            component={renderInput}
                            validate={[required, email]}
                        />
                        <br/>
                        <Button type="submit"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disabled={!valid || submitting}>
                            {submitting ? 'Requesting Password Reset...' : 'Request Password Reset'}
                        </Button>
                        {error && <FormError>{error}</FormError>}
                    </Form>
                </Grid>
            </Grid>
        );
    }
});

const RequestResetPassword = ({doRequestResetPassword, ...rest}) => {
    const {auth: {AuthLinks, viewPlugin: {View, Heading}}} = rest;
    return (
        <View>
            <RequestResetPasswordForm onSubmit={doRequestResetPassword} {...rest} />
        </View>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        doRequestResetPassword: data => requestResetPassword(data, dispatch)
    };
};

const ResetPasswordPage = connect(null, mapDispatchToProps)(RequestResetPassword);

export {
    RequestResetPasswordForm,
    RequestResetPassword,
    ResetPasswordPage as default
};