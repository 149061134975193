import React from "react"
import PropTypes from "prop-types"

import Button from '@material-ui/core/Button';

import { fetchWithJWT }  from "./FetchWithJWT"


class PicksFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      feedback: props.item.feedback
    };
  }
  
  updateFeedback = () => {
    let feedback = this.state.feedback
    let body = JSON.stringify({feedback: feedback })
    fetchWithJWT(`picks/${this.props.item.id}`, 'PUT', {body: body})
      .then((item)=>{
        this.setState({ editable: false }) 
    })
  }

  handleChange = (event) => {
    this.setState({feedback: event.target.value});
  };
  


  render () {
    let button, text_field
    
      if (this.state.editable){
        text_field = (<textarea id="noter-text-area" name="textarea" value={this.state.feedback} onChange={this.handleChange} />)
        button = (<Button variant="contained" onClick={() => this.updateFeedback()}>Send feedback</Button> )
      } else {
        if (this.state.feedback != ""){
          text_field = (<div>{this.state.feedback}</div>)
          button = (<Button variant="contained" onClick={() => this.setState({ editable: true })}>Update feedback</Button> )
        } else {
          button = (<Button variant="contained" onClick={() => this.setState({ editable: true })}>Leave feedback</Button> )
        };  
      }; 
    
    return (
      <React.Fragment>
        {text_field}
        {button}
      </React.Fragment>
    );
  }
}


export default PicksFeedback
