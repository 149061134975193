import React, {Component} from "react"
import PropTypes from "prop-types"

import {Link} from 'react-router-dom';
import {Button, CircularProgress, Grid, Snackbar, TextField} from '@material-ui/core';


import Select from '@material-ui/core/Select';


import {fetchWithJWT} from "./FetchWithJWT";
import {history} from "./setup";
import Alert from "@material-ui/lab/Alert/Alert";
import Typography from "@material-ui/core/Typography";


class PasswordSettings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            saved: false,
            error: null,
            invalid_password: false
        };
        this.passwordRef = React.createRef();
    }

    updateShipping = () =>{
        if (!this.validatePassword()) {
            return
        }
        let body = JSON.stringify({password: this.passwordRef.current.value})
        fetchWithJWT(`account/password`, 'PUT', {body: body})
            .then((data)=>{
                if (data.status == 'ok') {
                    this.setState({saved: true, error: null})
                } else {
                    this.setState({error: data.message})
                }
            })
    }

    handleClose = () => {
        this.setState({saved: false})
    }

    validatePassword = () => {
      let value = this.passwordRef.current.value
      if (value.length < 8){
        this.setState({invalid_password: true})
        return false
      }
      this.setState({invalid_password: false})
      return true
    }

    render() {
        //console.log('ShippingSettings render')
        //console.log(shipping)
        return (
            <Grid container spacing={4} justify={'center'}>
                { this.state.error && (<Grid item xs={12}><Alert severity="error">{this.state.error}</Alert></Grid>)}
                
                <React.Fragment>

                    <Grid item xs={8} container>
                        <TextField
                            inputRef={this.passwordRef}
                            variant="outlined"
                            label="New Password"
                            fullWidth={true}
                            defaultValue=""
                            error={this.state.invalid_password}
                            helperText={this.state.invalid_password ? 'Password has to be at least 8 characters': ''}
                        />
                    </Grid>

                    <Grid item xs={4} container justify={"flex-end"} alignContent={'center'}>
                        <Grid item >
                            <Button variant="contained" color="secondary" onClick={() => this.updateShipping()}>UPDATE PASSWORD</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>

                
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.saved}
                    autoHideDuration={2000}
                    message="Your password has been successfully updated"
                    onClose={this.handleClose}
                />
            </Grid>
        );
    }
};



export default PasswordSettings
