import React from "react"
import PropTypes from "prop-types"

import {BottomNavigation, BottomNavigationAction, Grid} from '@material-ui/core';



import Typography from '@material-ui/core/Typography';
import PaymentIcon from '@material-ui/icons/Payment';
import {Link} from 'react-router-dom'

import { fetchWithJWT }  from "./FetchWithJWT"

class UserCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { card:{}, shipping: {address:{}}}
        };
    }
    componentWillMount(){
        fetchWithJWT(`/account`)
            .then((data) => {this.setState({ user: data}) });
    }

    preventDefault = (event) => event.preventDefault();
    render () {
        return (
            <Grid container>
                {this.state.user.card && (
                    <Grid item xs={12} container>
                        <Grid item >
                            <PaymentIcon style={{ color: '#a36b7a', fontSize: 30 }} />
                        </Grid>
                        <Grid item >
                        {`**** **** **** ${this.state.user.card.last4} ${this.state.user.card.exp_month}/${this.state.user.card.exp_year}`}
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                      {this.state.user.display_name}
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.user.shipping.address.line1}
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.user.shipping.address.city}, {this.state.user.shipping.address.state} {this.state.user.shipping.address.postal_code}
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/settings">UPDATE</Link>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default UserCard
