import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {fetchWithJWT} from "./FetchWithJWT";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import StatusPage from "./StatusPage";
import {
    useStripe,
    useElements,
    Elements,
    LinkAuthenticationElement,
    PaymentElement,
} from "@stripe/react-stripe-js";


export default function StripeSettingsPage2() {
    const [clientSecret, setClientSecret] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetchWithJWT("account/create_intent", "POST")
            .then((data)=>{
                console.log(data)
                if (data.status == 'ok'){
                    setClientSecret(data)
                }
                if (data.status == 'error'){
                    setError(data.message)
                }
            }
        )}, []);

    /*const appearance = {
        theme: 'stripe',
    };*/

    const appearance = {/* ... */};
    const loader = 'auto';
    return (
        <div>
            {clientSecret && (
                <Elements stripe={loadStripe(clientSecret.stripe_pk)} options={{clientSecret: clientSecret.client_secret, appearance, loader}}>
                    <CheckoutForm />
                </Elements>
            )}
            {error && (
                <StatusPage severity="error" message={error}/>
            )}
        </div>
    );
}

function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [comp, setComp] = useState({status: 'init'});
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        const result = await stripe.confirmSetup({
            elements,
            redirect: "if_required",
            //confirmParams: {
            //    return_url: `${window.location.origin}/checkouts5/return`,
            //}
        });

        console.log(result);
        if (result.error) {
            setComp({status: 'error', error: result.error.message});
            Rollbar.error(result.error.message);
        } else {
            let body = JSON.stringify({intent: result.setupIntent})
            fetchWithJWT("account/card", 'PUT', {body: body})
                .then((data)=>{
                    console.log(data)
                    if (data.status == 'ok'){
                        setComp({status: 'success'})
                    } else {
                        setComp({status: 'error', error: data.message})
                    }
                })
        }
    };

    return (
        <React.Fragment>
            <br/>
            { comp.status == 'success' && (
                <StatusPage severity="success" message="Payment method updated"/>
            )}
            { comp.status == 'error' && (
                <StatusPage severity="error" message={comp.error}/>
            )}
            { comp.status == 'init' && (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} justify={'center'}>
                        <Grid item xs={12} sm={11}>
                            <h3>Update Payment Method</h3>
                            <LinkAuthenticationElement />
                            <PaymentElement />
                        </Grid>
                        <Grid item xs={12} sm={11} justify={'center'}>
                            <Grid item>
                                <Button type="submit" variant="contained" color="secondary" disabled={isLoading || !stripe || !elements}>SAVE PAYMENT METHOD</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </React.Fragment>
    );
}