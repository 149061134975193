import React from 'react';

import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import {Button, Grid} from '@material-ui/core';

import { fetchWithJWT }  from "./FetchWithJWT"

import StatusPage from './StatusPage'
import '../styles/common.css';

import Alert from '@material-ui/lab/Alert';
import HeaderTitle from "./HeaderTitle";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {status: 'init'};
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const {source, error} = await stripe.createSource(cardElement, {type: 'card'}) //stripe.createPaymentMethod({
    //  type: 'card',
    //  card: cardElement,
    //});

    if (error) {
      console.log('[error]', error);
      this.setState({status: 'error', error: JSON.stringify(error)})
    } else {
  
      console.log('[Source]', source);
      let body = JSON.stringify({user: {source: source} })
      fetchWithJWT("account/card", 'PUT', {body: body})
	      .then((data)=>{
	      	console.log(data)
	      	if (data.status == 'ok'){
	      		this.setState({status: 'success'})
	      	} else {
	      		this.setState({status: 'error', error: data.message})
	      	}
	    })
      
    }
  };

  render() {
    const {stripe} = this.props;
    return (
        <Grid container>
          <HeaderTitle title="Payment Method"/>
          <br/>
          <br/>
          <Grid item md={1}></Grid>
          <Grid item md={10} xs={12} style={{padding: '0 10px'}}>
            <div className="StripeCardUpdate">
              { this.state.status == 'success' && (
                  <StatusPage severity="success" message="Payment method updated"/>
              )}
              { this.state.status == 'error' && (
                  <StatusPage severity="error" message={this.state.error}/>
              )}
              { this.state.status == 'init' && (
                <form onSubmit={this.handleSubmit}>
                  <br/>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                  <br/>
                  <br/>
                  <Button type="submit" variant="contained" color="secondary" disabled={!stripe}>SAVE PAYMENT METHOD</Button>
                </form>
              )}
            </div>
          </Grid>
        </Grid>
    );
  }
}

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <CheckoutForm elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

const stripePromise = loadStripe(window.stripeKey);

const StripeSettingsPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );
};

export default StripeSettingsPage