import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import SurveyTitleComp from "./SurveyTitleComp";


export default function SurveyDropdownComp({field, response, onSubmit, id}) {
    console.log('SurveyTextFieldComp');
    console.log(response);
    console.log(field);

    const [value, setValue] = React.useState(response || {
        "text": "",
        "type": "text",
        "field": {
            "ref": field.ref,
            "type": "dropdown"
        }
    });

    const handleChange = (event) => {
        let newValue = {...value};
        newValue['text'] = event.target.value;
        setValue(newValue);
        //console.log(newValue);
    };

    const verifyResponse = () => {
        if (!(field.validations && field.validations.required)) return true;
        console.log(value);
        if (value && value.text) return true;
        return false;
    };

    const onSaveResponse = () => {
        onSubmit(value);
    };

    return (
        <SurveyTitleComp
            id={id}
            field={field}
            onSubmit={onSaveResponse}
            submitDisabled={!verifyResponse()}
        >
            <FormControl fullWidth>
                <Select
                    labelId="survey-dropdown-comp-label"
                    id="survey-dropdown-comp"
                    autoWidth
                    value={value.text}
                    onChange={handleChange}
                >
                    <MenuItem value="" disabled>
                        Select an option
                    </MenuItem>
                    { field.properties.choices.map((choice, i) =>
                        (<MenuItem key={i} value={choice.label}>{choice.label}</MenuItem>)
                    )}
                </Select>
            </FormControl>
        </SurveyTitleComp>
    )
}