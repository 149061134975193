import React from "react"
import PropTypes from "prop-types"

import Picks from './Picks'



class PicksPage extends React.Component {
  render () {
    return (
      <React.Fragment>

        <Picks />

        
      </React.Fragment>
    );
  }
}


export default PicksPage
