import React from "react";
import TextField from "@material-ui/core/TextField";
import SurveyTitleComp from "./SurveyTitleComp";


function createInitialValue(response, field) {
    return response || {
        text: "",
        type: "text",
        field: {
            ref: field.ref,
            type: "short_text",
        },
    };
}

export default function SurveyTextFieldComp({field, response, onSubmit, id}) {
    //console.log('SurveyTextFieldComp');
    //console.log(response);
    //console.log(field);

    const [value, setValue] = React.useState(createInitialValue(response, field));
    const handleChange = (event) => {
        setValue({
            ...value,
            text: event.target.value,
        });
    }

    const isResponseValid = () => {
        if (!(field.validations && field.validations.required)) return true;
        if (value && value.text) return true;
        return false;
    }

    const handleSaveResponse = () => {
        onSubmit(value);
    }

    return (
        <SurveyTitleComp id={id}
                         field={field}
                         onSubmit={handleSaveResponse}
                         submitDisabled={!isResponseValid()}
        >
            <TextField id="typeform_textfield_id"
                       value={value.text}
                       onChange={handleChange}
                       placeholder="Type your answer here..."
                       fullWidth />
        </SurveyTitleComp>
    )
}