import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

import {Box, CardMedia, Grid} from '@material-ui/core'

import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {Link} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 429,
        height: 527,
        maxWidth: '100%'
    },
    feedback:{
        minWidth: 350,
    },
}));

function ThankYouPage(props){
    const classes = useStyles();

    return (
        <Grid container spacing={1} style={{marginTop: '20px'}} >
            <Grid item xs={12} container justify={'center'} spacing={1}>
                <Grid item xs={12}>
                    <Typography  variant="h5" style={{fontWeight: 'bold', color: '#525f7f'}} align={'center'}>
                        Thank You, {props.currentUser.firstName.split(" ")[0]}!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography  variant="body1" style={{color: '#525f7f'}} align={'center'}>
                        Your feedback has been saved and will be shared with your stylist.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container justify={'center'}>
                <Box m={2} >
                    <Grid item >
                        <Link to="/">
                            <CardMedia
                                image={"thankyou.png"}
                                className={classes.cover}
                            />
                        </Link>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    };
};

export default connect(mapStateToProps)(ThankYouPage);




