import React, {Component} from "react"
import {Grid, TextField} from '@material-ui/core';
import {fetchWithJWT} from "./FetchWithJWT";
import Typography from "@material-ui/core/Typography";
import InputAdornment from '@material-ui/core/InputAdornment';

const grayColor1 = 'rgba(0, 0, 0, 0.54)'
const text1Style = {color: grayColor1, fontSize: '0.8rem'}


export default class SocialNetworkSettings extends Component{
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            instagram: props.user.smdata ? props.user.smdata.instagram : '',
            pinterest: props.user.smdata ? this.pinterestUrlHandler(props.user.smdata.pinterest, null): this.pinterestUrlHandler('', null),
            //youtube: props.user.smdata ? props.user.smdata.youtube : '',
        };
    }

    pinterestUrlHandler = (url, oldDomain) => {
        let result = {};
        if (url == null) {
            result['domain'] = 'pinterest.com/';
            result['path'] = '';
        } else if (url.includes('pin.it/')){
            result['domain'] = 'pin.it/';
            result['path'] = url.split('pin.it/')[1];
        } else if (url.includes('pinterest.com/')){
            result['domain'] = 'pinterest.com/';
            result['path'] = url.split('pinterest.com/')[1];
        } else {
            if (url.length != 0 && (oldDomain != null || oldDomain.length > 0))
                result['domain'] = oldDomain;
            else
                result['domain'] = 'pinterest.com/';
            result['path'] = url;
        }
        return result;
    }

    updateData = (event) => {
        let key = event.target.name;
        let value = {...this.state};
        value[key] = event.target.value;
        this.setState(value);
        this.updateInBackend(value);
    }

    updatePinterest = (event) => {
        let newValue = event.target.value.trim();
        let state = {...this.state};
        let url = this.pinterestUrlHandler(newValue, state.pinterest.domain);
        state['pinterest'] = url;
        this.setState(state);
        this.updateInBackend(state);
    }

    updateInBackend = (newState) => {
        let url = newState['pinterest'];
        let data = {...newState};
        data['pinterest'] = url.domain + url.path;
        let body = JSON.stringify({smdata: data})
        fetchWithJWT(`account/smdata`, 'PUT', {body: body})
            .then((data)=>{
                if (data.status == 'ok') {}
            })
    }

    render() {
        //console.log('ShippingSettings render')
        //console.log(this.state)
        return (
            <Grid container spacing={4} justify={'flex-start'}>
                <Grid item container xs={12}>
                    <Grid item container xs={12} md={3} >
                        <Typography  variant="h5" component="h5" style={{fontWeight: 600}}>
                            Social Media
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} md={9} >
                        <Typography variant="body1" style={text1Style}>
                            Share your Instagram and/or Pinterest handle with your stylist if you have one!
                            This information will be kept confidential and only be used for styling.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6} >
                    <TextField
                        variant="outlined"
                        label="Instagram Handle"
                        fullWidth={true}
                        value={this.state.instagram}
                        onChange={this.updateData}
                        name="instagram"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">instagram.com/</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item container xs={12} md={6} >
                    <TextField
                        variant="outlined"
                        label="Pinterest Handle"
                        fullWidth={true}
                        value={this.state.pinterest.path}
                        onChange={this.updatePinterest}
                        name="pinterest"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{this.state.pinterest.domain}</InputAdornment>,
                        }}
                    />
                </Grid>

                {false && (<Grid item container xs={12} md={6} >
                    <TextField
                        variant="outlined"
                        label="Youtube Handle"
                        fullWidth={true}
                        value={this.state.youtube}
                        onChange={this.updateData}
                        name="youtube"
                    />
                </Grid>)}
            </Grid>
        );
    }
};




