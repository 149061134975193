import React, {Component} from "react"
import PropTypes from "prop-types"

import {Button, Grid, Snackbar, TextField} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
//import Link from '@material-ui/core/Link';

import {
    PAUSE_SUBSCRIPTION,
    EVERY_MONTH_SUBSCRIPTION,
    EVERY_OTHER_MONTH_SUBSCRIPTION,
    EVERY_3_MONTH_SUBSCRIPTION
} from "../dashboard/AdminConsts";


import {fetchWithJWT, postPageUploadEvent} from "./FetchWithJWT";
import {history} from "./setup";
import AdminMessageDialog from "../dashboard/AdminMessageDialog";

const cancelSubscriptionReason = [
    "didn't like the pieces sent",
    "to expensive",
    "don't need more clothing",
    "other"
]

const reasonLabelStyle = {
    padding: '10px 20px',
    backgroundColor: '#a36b7a',
    borderRadius: '4px',
    color: 'white',
    textAlign: 'center',
}

const reasonBtnStyle = {
    justifyContent: 'left',
    textTransform: 'capitalize',
}

class FrequencySettings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            frequency: props.user.frequency,
            saved: false,
            showCancelReason: false,
            reason: null,
            reasonText: "",
            showSkipConfirmationBox: false,
            showSkipButton: true,
            showMessage: null,
        };
    }
    /*componentWillMount(){
        fetchWithJWT(`/account`)
            .then((data) => {this.setState({ frequency: data.frequency}) });
    }*/

    updateFrequency = (value) => {
        let body = JSON.stringify({frequency: value})
        fetchWithJWT(`account/frequency`, 'PUT', {body: body})
            .then((data)=> {
                if (data.status === 'error') this.setState({showMessage: data.message});
                if (data.status === 'ok') this.setState({frequency: value});
            });
    }

    handleChange = (event) => {
        this.updateFrequency(event.target.value)
    };

    cancelSubscription = () => {
        postPageUploadEvent();
        history.push('/cancel_subscription')
    }

    skipMyNextShipment = () => {
        this.setState({showSkipConfirmationBox: true, showSkipButton: false});
    }

    confirmSkipping = () => {
        this.setState({ showSkipConfirmationBox: false });
        fetchWithJWT(`account/skip_package`, 'POST', )
            .then((data)=>{
                if (data.status === 'error' || data.status === 'ok') {
                    this.setState({
                        showSkipButton: false,
                        showMessage: data.message,
                    });
                }
            })
    }

    denySkipping = () => {
        this.setState({showSkipButton: true, showSkipConfirmationBox: false});
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.frequency != PAUSE_SUBSCRIPTION) && (
                    <Grid container spacing={2}  justify={'flex-start'}>
                        <Grid item xs={12} container justify={'flex-start'}>
                            <Grid item >
                                <Typography variant="h6">
                                  How often would you like to receive a box?
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify={'flex-start'}>
                            <Grid item >
                                <Typography variant="body1">
                                  You can change this frequency or cancel at any time.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item md={8} xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth={true} >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.frequency}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={EVERY_MONTH_SUBSCRIPTION}>Every Month</MenuItem>
                                        <MenuItem value={EVERY_OTHER_MONTH_SUBSCRIPTION}>Every Other Month</MenuItem>
                                        <MenuItem value={EVERY_3_MONTH_SUBSCRIPTION}>Every 3 Months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} container justify={"flex-start"} spacing={2}>
                                <AdminMessageDialog open={this.state.showMessage != null}
                                                    onClose={() => this.setState({showMessage: null})}
                                                    message={this.state.showMessage}
                                                    title="Thanks for letting us know!"
                                                    position="bottom"
                                />

                                { this.state.showSkipButton && (
                                    <Grid item >
                                        <Button size="small" onClick={()=>this.skipMyNextShipment()}>
                                            Skip my next shipment
                                        </Button>
                                    </Grid>
                                )}
                                { this.state.showSkipConfirmationBox && (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Button size="small" onClick={null}>
                                                Skip your next shipment?
                                            </Button>
                                        </Grid>
                                        <Grid item  xs={6}>
                                            <Button size="large" variant="outlined" onClick={()=>this.confirmSkipping()} style={{marginRight: '10px'}}>
                                                Yes
                                            </Button>
                                            <Button size="large" variant="outlined" onClick={()=>this.denySkipping()}>
                                                No
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                            <Grid item xs={12} container justify={"flex-start"}>
                                <Grid item >
                                    <Button size="small" onClick={()=>this.cancelSubscription()}>
                                        Cancel my subscription
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
};



export default FrequencySettings
