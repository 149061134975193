import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {Grid} from '@material-ui/core'

import Typography from '@material-ui/core/Typography';
import CustomerRequest from "./CustomerRequest";
import {fetchWithJWT} from "./FetchWithJWT";
import QuizItems from "./QuizItems";
import HeaderTitle from "./HeaderTitle";
import Collapse from "@material-ui/core/Collapse/Collapse";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from "@material-ui/core/IconButton";
import UserSurvey from "./survey/UserSurvey";


const grayColor1 = '#f6f7f7';
const grayColor2 = 'rgba(0, 0, 0, 0.54)';
const grayColor3 = '#dadada';
const title1Style = {backgroundColor: grayColor1, padding: '8px'};
const text1Style = {fontWeight: 'bold', color: grayColor2};
const text2Style = {color: grayColor2};
const borderStyle = {border: '1px solid '+grayColor3};

export default class StyleProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quiz: null,
            extended: false,
        }
    }

    componentWillMount(){
        fetchWithJWT(`/quiz`)
            .then((data) => {
                this.setState({quiz: data})
            });
    }

    onExtendClick = () =>{
        this.setState({expanded: !this.state.expanded})
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTitle title="Style Profile"/>
                <Grid container spacing={3} style={{padding: '0 10px'}}>

                    <Grid item xs={12}>
                        <CustomerRequest/>
                    </Grid>
                    {this.state.quiz && (
                        <Grid item xs={12}>
                            <Grid container style={borderStyle} >
                                <Grid container item xs={12} style={title1Style} alignItems={'center'}>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1" style={text1Style}>
                                            Take a Style Quiz!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10} md={8}>
                                        <Typography variant="body1" style={text2Style}>
                                            Help your stylist get a better feel for what you like by picking your favorites
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={1} >
                                        <IconButton onClick={this.onExtendClick} id="style_profile_extend_btn_id">
                                            {!this.state.expanded && (<ArrowDownwardIcon />)}
                                            {this.state.expanded && (<ArrowUpwardIcon />)}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse in={this.state.expanded} timeout="auto">
                                        { Object.keys(this.state.quiz).map((category, i) => (
                                            <Grid key={i} item xs={12}>
                                                <QuizItems items={this.state.quiz[category]} title={category} />
                                            </Grid>
                                        ))}
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {false && <UserSurvey/>}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }


}





