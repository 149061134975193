import {SELECTED_ITEM_KEEPING_STATUS} from "../dashboard/AdminConsts";
import {getUserInfo} from "./FetchWithJWT";

const PINTEREST_TAG_ID = '2612501048400'
export function viewPage(order){
  //console.log('viewPage',order)
  updateUserInfo();
  pintrk('track', 'pagevisit', {
    order_id: order.id,
    value: order.picked_items.reduce((a, si) => si.item.price + a, 0) / 100,
    currency: 'USD',
    //event_id: 'eventId0001',
    //promo_code: 'WINTER10',
    line_items: order.picked_items.map((si) => {
      const item = si.item;
      return {
        product_name: item.name,
        product_id: item.id,
        product_category: item.category,
        product_price: item.price / 100,
        product_quantity: 1,
        product_brand: item.brand,
      };
    })
  });
}

function updateUserInfo() {
  const user = getUserInfo();
  if (user){
    pintrk('load', PINTEREST_TAG_ID, {em: user.email});
  }
}

export function addToCart(selectedItem) {
  //updateUserInfo();
  const item = selectedItem.item;
  pintrk('track', 'addtocart', {
    order_id: selectedItem.order_id,
    currency: 'USD',
    value: item.price/100,
    line_items: [{
      product_name: item.name,
      product_id: item.id,
      product_category: item.category,
      product_price: item.price/100,
      product_quantity: 1,
      product_brand: item.brand,
    }]
  });
}


export function checkoutOrder(order) {
  updateUserInfo();
  const price = order.total_price ? order.total_price : order.price;
  pintrk('track', 'checkout', {
    order_id: order.id,
    value: price/100,
    currency: 'USD',
    //event_id: 'eventId0001',
    // promo_code: 'WINTER10',
    line_items: order.picked_items.filter((si)=> si.status === SELECTED_ITEM_KEEPING_STATUS).map((si)=> {
      const item = si.item;
      return {
        product_name: item.name,
        product_id: item.id,
        product_category: item.category,
        product_price: item.price/100,
        product_quantity: 1,
        product_brand: item.brand,
      };
    })
  })
}