import React, {Component} from "react"
import PropTypes from "prop-types"

import {connect} from 'react-redux';
import {withAuth} from 'react-devise';
import {Link} from 'react-router-dom';
import {CircularProgress, Grid} from '@material-ui/core';

import {logout} from "react-devise/lib/actions";
import {fetchWithJWT} from "./FetchWithJWT";
import HeaderTitle from "./HeaderTitle";

class SettingsPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: null
        };

        this.frequencyLabel = {
            10: "Every Month",
            20: "Every Other Month",
            30: "Every 3 Month",
            '-100': "Pause Subscription"
        }
    }
    componentWillMount(){
        fetchWithJWT(`/account`)
            .then((data) => {this.setState({ user: data}) });
    }


   render() {
       let {currentUser, doLogout, auth: {AuthLinks}} = this.props
       //console.log(currentUser)
       return (
           <React.Fragment>
               <HeaderTitle title="Account Settings"/>
               <Grid container spacing={4} justify={'center'} style={{padding: '0 10px'}}>
                   <Grid item xs={12} container justify={'center'}>
                       <Grid item>
                           <a href="/" onClick={() => doLogout()}>Sign Out</a>
                       </Grid>
                   </Grid>
                   {!this.state.user && (<Grid item><CircularProgress color="secondary"/></Grid>)}
                   {this.state.user && (
                       <React.Fragment>
                           <Grid item xs={12} container>
                               <Grid item xs={4} container>
                                   YOUR LOGIN AND PASSWORD
                               </Grid>

                               <Grid item xs={4}>
                                   {currentUser.email}<br/>
                                   ********
                               </Grid>

                               <Grid item xs={4} container>
                                 <Link to="/password_settings">Update password</Link>
                               </Grid>
                           </Grid>

                           <Grid item xs={12} container>
                               <Grid item xs={4} container>
                                   YOUR FREQUENCY
                               </Grid>

                               <Grid item xs={4} container>
                                   {this.frequencyLabel[this.state.user.frequency]}
                               </Grid>

                               <Grid item xs={4} container>
                                   <Link to="/frequency_settings">Manage frequency</Link>
                               </Grid>
                           </Grid>

                           <Grid item xs={12} container>
                               <Grid item xs={4} container>
                                   SHIPPING ADDRESS
                               </Grid>

                               <Grid item xs={4} container>
                                   {this.state.user.shipping.name}<br/>
                                   {this.state.user.shipping.address.line1}<br/>
                                   {this.state.user.shipping.address.city},
                                   &nbsp;
                                   {this.state.user.shipping.address.postal_code}
                               </Grid>

                               <Grid item xs={4} container>
                                   <Link to="/shipping_settings">Edit shipping address</Link>
                               </Grid>
                           </Grid>

                           <Grid item xs={12} container>
                               <Grid item xs={4} container>
                                   PAYMENT INFO
                               </Grid>

                               <Grid item xs={4}>
                                   {`${this.state.user.card.brand}`}<br/>
                                   {`**** **** **** ${this.state.user.card.last4}`}<br/>
                                   {`${this.state.user.card.exp_month}/${this.state.user.card.exp_year}`}
                               </Grid>

                               <Grid item xs={4} container>
                                   <Link to="/stripe_settings">Edit payment info</Link>
                               </Grid>
                           </Grid>
                       </React.Fragment>
                    )}


               </Grid>
           </React.Fragment>
       );
   }
};

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  };
};

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => logout(dispatch)
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(withAuth(SettingsPage));
