import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

import {Button, CircularProgress, Grid} from '@material-ui/core'

import Typography from '@material-ui/core/Typography';
import HeaderTitle from "./HeaderTitle";
import {SELECTED_ITEM_RETURNING_STATUS} from "../dashboard/AdminConsts";
import {fetchWithJWT} from "./FetchWithJWT";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {
    PAUSE_SUBSCRIPTION,
    EVERY_MONTH_SUBSCRIPTION,
    EVERY_OTHER_MONTH_SUBSCRIPTION,
    EVERY_3_MONTH_SUBSCRIPTION
} from "../dashboard/AdminConsts";

const shipmentFrequency = {
    [EVERY_MONTH_SUBSCRIPTION]: 'Monthly Shipments',
    [EVERY_OTHER_MONTH_SUBSCRIPTION]: 'Bimonthly Shipments',
    [EVERY_3_MONTH_SUBSCRIPTION]: 'Quarterly Shipments'
}

const useStyles = makeStyles({
    scrollPaper: {
        alignItems: 'flex-start',
        marginTop: 270,
    }
});


export default function ShipmentSchedulePage(props){

    const [user, setUser] = useState(null);
    const [showStartSubscriptionBlock, setStartSubscriptionBlock] = useState(false);
    const [showExtendDateBlock, setExtendDateBlock] = useState(true);
    const [deadlineExtended, setDeadlineExtended] = useState(false);
    const [orderCanBeExtended, setOrderCanBeExtended] = useState(false);
    const [showExtendDateConfirmationPopup, setExtendDateConfirmationPopup] = useState(false);
    const [restartSubscriptionBtnDisable, setRestartSubscriptionBtnDisable] = useState(false);


    const classes = useStyles();

    useEffect(() => {
        fetchWithJWT(`/account`)
            .then((data) => {setUser(data) });
        fetchWithJWT(`/account/has_order_for_checkout`)
            .then((data) => {
                //console.log(data);
                if (data.status == 'ok') {
                    //setExtendDateBlock(data.has_order_for_checkout);
                    setDeadlineExtended(data.extend_date_at != null );
                    setOrderCanBeExtended(data.allow_to_extend_date);
                }
            });
    }, []);

    const extandDate = () => {
        fetchWithJWT(`account/extend_date`, 'POST', )
            .then((data)=>{
                if (data.status == 'ok') {
                    setExtendDateConfirmationPopup(true);
                    setDeadlineExtended(true);
                    setOrderCanBeExtended(false);
                }
                if (data.status == 'error') alert(data.message);
            })
    }

    const restartSubscription = () => {
        setRestartSubscriptionBtnDisable(true);
        let body = JSON.stringify({frequency: EVERY_MONTH_SUBSCRIPTION})
        fetchWithJWT(`account/frequency`, 'PUT', {body: body})
            .then((data)=>{
                setStartSubscriptionBlock(true);
            })
    }

    const handleDialogClose = () => {
        setExtendDateConfirmationPopup(false);
    }
    //console.log(`showExtendDateBlock-${showExtendDateBlock}; deadlineExtended-${deadlineExtended}; orderCanBeExtended-${orderCanBeExtended}`);
    return (
        <React.Fragment>
            <HeaderTitle title="Shipment Schedule"/>
            <br/><br/><br/>
            <Grid container spacing={4} justify={"center"} style={{padding: '0 10px'}}>
                <Grid item container spacing={4} md={8} xs={12} style={{backgroundColor: '#F6F7F7'}}>
                    {!user && (<Grid item container justify={"center"}><Grid item><CircularProgress color="secondary"/></Grid></Grid>)}
                { !showStartSubscriptionBlock && user && (user.frequency == PAUSE_SUBSCRIPTION) && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h3" align={'center'} style={{color: '#173D54', fontSize: '1.5rem', fontWeight: 600}}>
                                You are not currently signed up to receive shipments
                            </Typography>
                        </Grid>
                        <Grid item container justify={"center"} xs={12}>
                            <Grid item>
                                <Button variant="contained"
                                        color="secondary"
                                        disabled={restartSubscriptionBtnDisable}
                                        onClick={restartSubscription} >
                                    Restart Subscription
                                </Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                { showStartSubscriptionBlock && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h3" align={'center'} style={{color: '#173D54', fontSize: '1.5rem', fontWeight: 600}}>
                                Awesome!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align={'center'} style={{color: '#173D54', fontWeight: 600}}>
                                We have renewed your subscription and you will be charged the $25 styling fee so that your stylist
                                can begin working on your order as soon as possible!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align={'center'} style={{color: '#173D54', fontWeight: 600}}>
                                You can check and update your shipment frequency on the account page
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )}
                { user && (user.frequency != PAUSE_SUBSCRIPTION) && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h3" align={'center'} style={{color: '#173D54', fontSize: '1.5rem', fontWeight: 600}}>
                                Your shipment frequency is set to:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" align={'center'} style={{color: '#173D54', fontSize: '1.3rem', fontWeight: 600}}>
                                {shipmentFrequency[user.frequency]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align={'center'} style={{color: '#173D54'}}>
                                You will receive an email with a tracking number once your package is shipped
                            </Typography>
                        </Grid>
                        { showExtendDateBlock && (
                            <React.Fragment>
                                { !deadlineExtended && orderCanBeExtended &&
                                    (<React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" align={'center'} style={{color: '#173D54', textDecoration: 'underline'}}>
                                                <b>Reminder:</b> You have 5 days to return any unwanted items. Need more time to try on your clothes? Click the button below.
                                            </Typography>
                                        </Grid>
                                        <Grid item container justify={"center"} xs={12}>
                                            <Grid item>
                                                <Button variant="contained" color="secondary" onClick={extandDate} >
                                                    Extend Date
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>)
                                }
                                { !deadlineExtended && !orderCanBeExtended &&
                                    (   <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" align={'center'} style={{color: '#173D54', textDecoration: 'underline'}}>
                                                <b>Reminder:</b> You have 5 days to return any unwanted items.
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>)
                                }
                                { deadlineExtended &&
                                    (<React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" align={'center'} style={{color: '#173D54', textDecoration: 'underline'}}>
                                                <b>Reminder:</b> You have 10 days to return any unwanted items.
                                            </Typography>
                                        </Grid>
                                        <Grid item container justify={"center"} xs={12}>
                                            <Grid item>
                                                <Button variant="contained" color="secondary" disabled >
                                                    Extend Date
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>)
                                }
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                </Grid>
            </Grid>
            <Dialog
                open={showExtendDateConfirmationPopup}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{scrollPaper: classes.scrollPaper}}
            >
                <DialogTitle id="alert-dialog-title">Thanks for letting us know!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have 5 additional days to return your items
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}





