import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import {
    SUBMIT_BTN_STYLE,
    TITLE_QUESTION_STYLE,
    DESCRIPTION_QUESTION_STYLE,
    TITLE_IMAGE_STYLE,
} from "./SurveyStyles";

function SurveyTitleComp({ children, field, submitDisabled, onSubmit, id }) {
    const { properties = {}, attachment, title } = field;
    const { description, hide_button, button_text } = properties;
    const showSubmitBtn = hide_button == null;

    return (
        <Grid item container xs={12} spacing={2} id={id}>
            <Grid item xs={12}>
                <Typography style={TITLE_QUESTION_STYLE}>{title}</Typography>
            </Grid>
            {description && (
                <Grid item xs={12}>
                    <Typography style={DESCRIPTION_QUESTION_STYLE}>
                        {description}
                    </Typography>
                </Grid>
            )}
            {attachment?.type === "image" && (
                <Grid item xs={12}>
                    <img src={attachment.href} style={TITLE_IMAGE_STYLE} />
                </Grid>
            )}
            {children && (
                <Grid item xs={12}>
                    {children}
                </Grid>
            )}
            {showSubmitBtn && (
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Button
                            id="survey_submit_btn_id"
                            onClick={onSubmit}
                            disabled={submitDisabled}
                            style={SUBMIT_BTN_STYLE}
                            color="secondary"
                            variant="contained"
                        >
                            {button_text || "OK"}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(SurveyTitleComp);