
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Typography from "@material-ui/core/Typography";
import QuizItem from "./QuizItem";

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Dialog from "@material-ui/core/Dialog/Dialog";
import {fetchWithJWT} from "./FetchWithJWT";



const pinkColor = '#a36b7a'
const grayColor1 = '#f6f7f7'
const grayColor2 = 'rgba(0, 0, 0, 0.54)'
const title1Style = {backgroundColor: grayColor1, padding: '16px'}
const text1Style = {fontWeight: 'bold', color: grayColor2, textTransform: 'capitalize'}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: grayColor1//theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        height: 1000,
    },
    icon: {
        color: pinkColor,
    },
    tileBar: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        right: '30px',
        top: '40px',
    },
    image: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    dialog: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        margin: 0,
    }
}));


function QuizItems(props){

    const [showImage, setShowImage] = useState(false)
    const [imageIndex, setImageIndex] = useState(null)
    const [liked, setLiked] = useState(false)
    const [items, setItems] = useState(props.items )

    const classes = useStyles();


    let clickTimeout = null
    const onSmallImageClick = (index) => {
        if (clickTimeout !== null) {
            //console.log('double click executes')
            clearTimeout(clickTimeout)
            clickTimeout = null
            onLikeClick(index)
        } else {
            clickTimeout = setTimeout(() => {
                //console.log('first click executes ')
                clearTimeout(clickTimeout)
                clickTimeout = null
                onSingleSmallImageClick(index)
            }, 300)
        }
    }

    const getGridListCols = () => {
        if (isWidthUp('md', props.width)) {
            return 6;
        }
        return 3;
    }

    const handleImageClose = () => {
        setShowImage(false)
    }

    const onSingleSmallImageClick = (index) => {
        setImageIndex(index)
        setLiked(items[index].liked)
        setShowImage(true)
    }

    const onLikeClick = (index) => {
        let item = items[index]
        fetchWithJWT('quiz/' + item.id + '/toggle', 'POST')
            .then((data)=>{
                if (data.status == 'ok') {
                    updateLikeIcon(index, data.value)
                }
            })
    }

    const updateLikeIcon = (index, liked) => {
        setLiked(liked)
        let tmp_items = [...items]
        tmp_items[index].liked = liked
        setItems(tmp_items)
    }

    return (
        <Grid container>
            <Grid item xs={12} style={title1Style}>
                <Typography variant="body1" style={text1Style}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.root}>
                    <GridList cellHeight={180}
                              cols={getGridListCols()}
                              spacing={5}
                              style={{
                                  width: '100%',
                                  height: Math.ceil(items.length/getGridListCols())*185,
                              }}
                    >
                        {items.map((item,i) => (
                            <GridListTile key={i}>
                                <QuizItem item={item}
                                          index={i}
                                          onImageClick={onSmallImageClick}
                                          onLikeClick={onLikeClick}
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
            </Grid>
            <Dialog
                open={showImage}
                onClose={handleImageClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{paper: classes.dialog}}
            >
                { imageIndex != null && (
                    <React.Fragment>
                    <div style={{
                        padding: '30px',
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        borderRadius: '25px',
                        minHeight: '400px',
                        minWidth: '300px',
                    }}
                    >
                        <img className={classes.image}
                             src={items[imageIndex].image_link}
                        />
                    </div>
                    <GridListTileBar
                        className={classes.tileBar}
                        titlePosition={'top'}
                        title={""}
                        subtitle={""}
                        actionIcon={
                            <IconButton aria-label={''} className={classes.icon} onClick={()=>onLikeClick(imageIndex)}>
                                { !items[imageIndex].liked && (<FavoriteBorderIcon style={{fontSize: '3rem'}} />)}
                                { items[imageIndex].liked && (<FavoriteIcon style={{fontSize: '3rem'}} />)}
                            </IconButton>
                        }
                    />
                    </React.Fragment>
                )}
            </Dialog>
        </Grid>
    )
}

export default  withWidth()(QuizItems);