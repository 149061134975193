

const AUTH_TOKEN = 'authToken';
const USER_INFO = 'persist:root';
const REDIRECT_IF_NOT_AUTH = "/users/login";

let getAuthToken = function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN);
};

let userLogined = function userLogined() {
  return localStorage.getItem(AUTH_TOKEN) && localStorage.getItem(USER_INFO)
}

let getUserInfo = function getUserInfo() {
  const rowData = localStorage.getItem(USER_INFO);
  if(!rowData) return;
  const rowUserInfo = JSON.parse(rowData);
  if(!rowUserInfo || !rowUserInfo.currentUser) return;
  return JSON.parse(rowUserInfo.currentUser);
}

let getBearerToken = function getBearerToken() {
  let authToken = getAuthToken();
  if (authToken) {
    return 'Bearer ' + authToken;
  }
};

let fetchWithJWT = function fetchWithJWT(url, method = 'GET', args={}) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  args['method'] = method
  let bearerToken = getBearerToken();
  if (bearerToken) {
    headers.authorization = bearerToken;
  };
  let request = { ...{}, ...args, headers: headers  }
  //console.log(request)
  return fetch(url, request)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else if (response.status == 401) {
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem(USER_INFO)
        window.location.href = REDIRECT_IF_NOT_AUTH;
      }
    });
};

let postPageUploadEvent = function postPageUploadEvent() {
  window.top.postMessage({'status': 'PETITEAVE_PAGE_RELOAD'}, '*');
}

export {fetchWithJWT, userLogined, postPageUploadEvent, getUserInfo}
