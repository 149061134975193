import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';;
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Typography from "@material-ui/core/Typography";
import {fetchWithJWT} from "./FetchWithJWT";


const pinkColor = '#a36b7a'

const useStyles = makeStyles((theme) => ({
    icon: {
        color: pinkColor,
    },
    tileBar: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    image: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    }
}));

export default function QuizItem(props){
    const classes = useStyles();

    return (
        <React.Fragment >
            <img className={classes.image}
                 src={props.item.thumb_link}
                 alt={props.item.name}
                 onClick={()=>props.onImageClick(props.index)}
            />
            <GridListTileBar
                className={classes.tileBar}
                titlePosition={'top'}
                title={""}
                subtitle={""}
                actionIcon={
                    <IconButton aria-label={''} className={classes.icon} onClick={()=>props.onLikeClick(props.index)}>
                        { !props.item.liked && (<FavoriteBorderIcon />)}
                        { props.item.liked && (<FavoriteIcon />)}
                    </IconButton>
                }
            />
        </React.Fragment>
    )
}