import React, {useState, useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"

import Collapse from '@material-ui/core/Collapse';

import { fetchWithJWT }  from "./FetchWithJWT"
import {BottomNavigation, BottomNavigationAction, Button, Grid, TextField} from "@material-ui/core";
import {
    SELECTED_ITEM_DISLIKE_STATUS,
    SELECTED_ITEM_LIKE_STATUS,
    SELECTED_ITEM_RETURNING_STATUS,
    SELECTED_ITEM_EXCHANGING_STATUS,
    SELECTED_ITEM_KEEPING_STATUS
} from "../dashboard/AdminConsts";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {addToCart} from "./Analitics";

const useStylesForNavBtn = makeStyles({
    root: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        //padding: 0,
        margin: '5px 10px 5px 0',
        minWidth: '10px',
        maxWidth: '60px',
        '&$selected': {
            color: 'white',
            borderColor: '#a36b7a',
            backgroundColor: '#a36b7a'
        }
    },
    label: {
        opacity: 1,
        color: 'black',
    },
    selected: {},
    iconOnly: {paddingTop: '6px'}
});

const useStylesForNavBtn2 = makeStyles({
    root: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        //padding: 0,
        margin: '5px 10px 5px 0',
        minWidth: '10px',
        '&$selected': {
            color: 'white',
            borderColor: '#a36b7a',
            backgroundColor: '#a36b7a'
        }
    },
    label: {
        opacity: 1,
        color: 'black',
        '&$selected': {
            color: 'white',
        }
    },
    selected: {
        color: 'white',
    }

});

const useStylesForReasonBtn = makeStyles({
    label: {
        justifyContent: 'left',
        textTransform: 'capitalize'
    }
});

const reasonLabelStyle = {
    padding: '10px 20px',
    backgroundColor: '#a36b7a',
    borderRadius: '4px',
    color: 'white',
    textAlign: 'center',
    marginRight: '40px',
}

const REASON_TO_BIG = 'too_big'
const REASON_TO_SMALL = 'too_small'
const REASON_TO_LONG = 'too_long'
const REASON_TO_EXPENSIVE = 'too_expensive'
const REASON_DID_NOT_LIKE = 'did_not_like'

const returnReasons = ['too_big', 'too_small', 'too_long', 'too_expensive','style','other']
const exchangeReasons = ['i_need_a_smaller_size', 'i_need_a_larger_size' ]


export default function DeliveredItemStatusPicker2(props) {
    //console.log(props)

    const navBtnStyle = useStylesForNavBtn();
    const navBtnStyle2 = useStylesForNavBtn2();
    const reasonBtnStyle = useStylesForReasonBtn();


    const [status, setStatus] = useState(props.item.status)
    const [reasons, setReasons] = useState(props.item.reasons)
    const [expandedReturnReason, setExpandedReturnReason] = useState(props.item.status == SELECTED_ITEM_RETURNING_STATUS)
    const [expandedExchangeReason, setExpandedExchangeReason] = useState(props.item.status == SELECTED_ITEM_EXCHANGING_STATUS)
    const [feedback, setFeedback] = useState(props.item.checkout_feedback)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogPosition, setDialogPosition] = useState(0)


    useEffect(() => {
        verify(status, feedback, reasons)
    }, []);

    const handleExtended = (newStatus) => {
        if (newStatus == SELECTED_ITEM_RETURNING_STATUS) {
            setExpandedReturnReason(true)
        } else {
            setExpandedReturnReason(false)
        }
        if (newStatus == SELECTED_ITEM_EXCHANGING_STATUS) {
            setExpandedExchangeReason(true)
        } else {
            setExpandedExchangeReason(false)
        }
    }

    const updateStatus = (event, newValue) => {
        if (newValue !== status) {
            let newStatus = parseInt(newValue);
            let body = JSON.stringify({picked_item: {status: newStatus, preview_feedback: '', reasons: ''}})
            fetchWithJWT(`picked_items/${props.item.id}`, 'PUT', {body: body})
                .then((item) => {
                    setReasons('');
                    setFeedback('');
                    verify(newValue, '', []);
                    if (newStatus === SELECTED_ITEM_KEEPING_STATUS) addToCart(props.item);
                })
                .catch((error)=>{
                    console.log(`catch error ${error} ${status}`);
                    handleExtended(status);
                    setStatus(status);
                })
            handleExtended(newStatus);
            setStatus(newStatus);
            if (newStatus == SELECTED_ITEM_EXCHANGING_STATUS) {
                let btnPosition =  event.target.getBoundingClientRect();
                setDialogPosition(btnPosition.y);
                setDialogOpen(true);
            }
        }
    }

    const verify = (status, feedback, reasons) => {
        if (status == SELECTED_ITEM_RETURNING_STATUS) {
            if (reasons.length > 0) {
                props.verify(true);
            } else {
                props.verify(false);
            }
        } else if (status == SELECTED_ITEM_KEEPING_STATUS) {
            props.verify(true);
        } else if (status == SELECTED_ITEM_EXCHANGING_STATUS) {
            if (reasons.length > 0) {
                props.verify(true);
            } else {
                props.verify(false);
            }
        } else {
            props.verify(false);
        }
    }

    const saveReasons = (new_reasons) => {
        let old_reasons = reasons;
        let body = JSON.stringify({picked_item: {reasons: new_reasons} })
        fetchWithJWT(`picked_items/${props.item.id}`, 'PUT', {body: body})
            .then((item)=>{verify(status, feedback, new_reasons)})
            .catch((error)=>{
                console.log(`catch error ${error} ${old_reasons}`);
                setReasons(old_reasons);
            });
        setReasons(new_reasons);
    }

    const handleExchangeReasonBtnClick  = (reason) => {
        saveReasons(reason)
    }

    const handleReturnReasonBtnClick = (reason) => {
        let result = "";
        if (reasons.includes(reason)) {
            result = reasons.split(" ").filter((r) => r != reason).join(" ");
        } else {
            result = reasons + " " + reason;
        }
        saveReasons(result);
    }

    const handleFeedbackChange = (event) => {
        sendFeedback(event.target.value);
        setFeedback(event.target.value);
    };

    const sendFeedback = (new_feedback) => {
        let old_feedback = feedback;
        let body = JSON.stringify({picked_item: {checkout_feedback: new_feedback} });
        fetchWithJWT(`picked_items/${props.item.id}`, 'PUT', {body: body})
            .then((item)=>{verify(status, new_feedback, reasons)})
            .catch((error)=>{
                console.log(`catch error ${error} ${old_feedback}`);
                setFeedback(old_feedback);
            });
        }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} justify={'center'}>
                <Grid item xs={12} >
                    <BottomNavigation
                        value={String(status)}
                        onChange={updateStatus}
                        style={{justifyContent: 'flex-start'}}
                    >
                        <BottomNavigationAction classes={navBtnStyle} value={String(SELECTED_ITEM_KEEPING_STATUS)} label="" icon={<ThumbUpIcon />} />
                        <BottomNavigationAction classes={navBtnStyle} value={String(SELECTED_ITEM_RETURNING_STATUS)} label="" icon={<ThumbDownIcon />} />
                        <BottomNavigationAction classes={navBtnStyle2} value={String(SELECTED_ITEM_EXCHANGING_STATUS)} label="Exchange" showLabel />
                    </BottomNavigation>
                </Grid>
                <Grid item xs={12} container>
                    <Collapse in={expandedReturnReason} timeout="auto">
                        <Grid item md={6} xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <div style={reasonLabelStyle}>
                                    Reason?
                                </div>
                            </Grid>

                        {returnReasons.map((r,i) => (
                            <Grid key={i} item xs={12}>
                                <Button
                                    classes={reasonBtnStyle}
                                    onClick={()=>handleReturnReasonBtnClick(r)}
                                    variant={ reasons.includes(r) ? 'contained' : 'outlined'}
                                    size="small"
                                    fullWidth={true}
                                >{r.split('_').join(' ')}</Button>
                            </Grid>
                        ))}
                        </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12} container>
                    <Collapse in={expandedExchangeReason} timeout="auto">
                        <Grid item xs={12} container spacing={1}>
                            {exchangeReasons.map((r,i) => (
                                <Grid key={i} item xs={12}>
                                    <Button
                                        classes={reasonBtnStyle}
                                        onClick={()=>handleExchangeReasonBtnClick(r)}
                                        variant={ reasons == r ? 'contained' : 'outlined'}
                                        fullWidth
                                    >{r.split('_').join(' ')}</Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        label={status == String(SELECTED_ITEM_EXCHANGING_STATUS) ? 'Reason for exchange' : 'Additional Feedback'}
                        onChange={handleFeedbackChange}
                        value={feedback}
                        style={{maxWidth: '500px'}}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        position: 'absolute',
                        top: dialogPosition,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Before we confirm your exchange, we’ll check to make sure we have this item in the size you are
                        requesting. If we have it, we’ll ship it out and charge you at that time. If we don’t, then we’ll
                        send a follow-up email letting you know.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );

}



