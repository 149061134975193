import React from "react"
import PropTypes from "prop-types"

import { fetchWithJWT }  from "./FetchWithJWT"

import Pick from "./Pick"

import {Grid, CircularProgress, Typography} from '@material-ui/core';
import {ORDER_ON_PREVIEW_STATUS} from "../dashboard/AdminConsts";
import {viewPage} from "./Analitics";


class Picks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picks: null
    };
  }
  componentDidMount(){
    fetchWithJWT(`/picks.json?status=${this.props.status}`)
      .then((data) => {
        this.setState({ picks: data});
        if(data && data.length > 0) viewPage(data[0]);
      });
  }

  render () {
  	let items;
    if (!this.state.picks){
      items = (<Grid item container xs={12} justify="center">
        <Grid item>
          <br/><br/>
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>)
    } else if (this.state.picks.length == 0){
      items = (
          <Grid item style={{padding: '50px'}}>
            <Typography variant="h5" align="center">
              {this.props.status == ORDER_ON_PREVIEW_STATUS ? "Please Note: We Only Send a Preview Before Your First Shipment." : ""}
            </Typography>
          </Grid>
      )
    } else {
      items = this.state.picks.map((item) => {
        return(
          <Pick key={item.id} item={item} />
        ) 
      })
    }
    return (
      <React.Fragment>
        { items }
      </React.Fragment>
    );
  }
}

export default Picks
