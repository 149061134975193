import React from "react"
import PropTypes from "prop-types"

import {Button, Divider, Grid, Snackbar, TextField} from '@material-ui/core';
import PickedItem from "./PickedItem"
import { fetchWithJWT, postPageUploadEvent }  from "./FetchWithJWT"
import {history} from './setup';
import Typography from "@material-ui/core/Typography";
import ImageViewer from 'react-simple-image-viewer';
import {ORDER_ON_PREVIEW_STATUS, ORDER_CONFIRMED_STATUS, ORDER_OUT_FOR_DELIVERY_STATUS} from "../dashboard/AdminConsts";
import HeaderTitle from "./HeaderTitle";

class Pick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.item.status,
      openSnackbar: false,
      showImage: null,
      snackbarMessage: 'Saved',
      //itemsVerifyStatus:[]
    };
    Array.from(Array(props.item.picked_items.length).keys()).forEach((i)=>{
        this.state['itemVerification'+i] = false
    })
    this.noteRef = React.createRef();
  }

  saveCheckoutPicks = () => {
    //console.log(picked_items)
    let body = JSON.stringify({pick: { checkout_feedback: this.noteRef.current.value }})
    fetchWithJWT(`picks/${this.props.item.id}`, 'PUT', {body: body})
        .then((data)=>{
            postPageUploadEvent();
            history.push('/checkout/'+this.props.item.id);
        })
  }

  handleClose = () => {
    this.setState({openSnackbar: false});
  }

  openImageViewer = (index) => {
      this.setState({showImage: this.props.item.picked_items[index].item.image_link});
  }

  closeImageViewer = () => {
      this.setState({showImage: null});
  }

  reasonsIsFilled = () => {
      let a = this.state;
      let l = this.props.item.picked_items.length;
      //console.log("reasonsIsFilled")
      //console.log(Array.from(Array(l).keys()).map((i)=>a['itemVerification'+i]))
      return Array.from(Array(l).keys()).every((i)=>a['itemVerification'+i]);
  }

  nextClick = () => {
      //console.log(picked_items)
      let body = JSON.stringify({pick: {preview_feedback: this.noteRef.current.value, status: ORDER_CONFIRMED_STATUS}})
      fetchWithJWT(`picks/${this.props.item.id}`, 'PUT', {body: body})
          .then((data)=> {
              if (data.status == 'ok')
              {
                  postPageUploadEvent();
                  history.push('/thank_you');
              } else {
                  alert(data.message);
                  location.reload();
              }
          })
          .catch(()=>{
              alert("Internet connection error");
              location.reload();
          });
  }

  updateVerification = (index, value) => {
      this.setState({['itemVerification'+index]: value});
  }

  render () {
  	let items = this.props.item.picked_items.map((item, index) => {
      return(
        <Grid key={item.id} item xs={12}>
            <PickedItem  pick_status={this.state.status}
                         onImageClick={()=>this.openImageViewer(index)}
                         item={item} verify={(value)=>this.updateVerification(index, value)} />
        </Grid>
      ) 
    })

    return (
        <React.Fragment>
            <HeaderTitle title={this.state.status == ORDER_OUT_FOR_DELIVERY_STATUS ? "Shipment #"+this.props.item.shipment_number : "Order Preview"}/>
            <Grid container spacing={2} style={{padding: '0 10px'}}>

              {(this.state.status == ORDER_OUT_FOR_DELIVERY_STATUS) && (

              <Grid item xs={12}>
                  <Typography  variant="h5" component="h5">
                      Once you receive your shipment, please let us know which items you're keeping
                  </Typography>
                  <Divider />
              </Grid>
              )}
             { items }
             <Grid item xs={12} container justify={"center"} spacing={1}>

                { (this.state.status == ORDER_ON_PREVIEW_STATUS) && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h5" >Have any additional feedback?</Typography>
                            <Typography style={{fontSize: 12}}>
                                Please let us know if there is anything else we should keep in
                                mind while picking items for you
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                variant="outlined"
                                label=""
                                fullWidth={true}
                                multiline
                                rows={5}
                                inputRef={this.noteRef}
                                //className={classes.feedbackInput}
                                defaultValue={this.props.item.preview_feedback}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography style={{fontSize: '1.25rem', color: '#a36b7a'}} align={'center'}>
                                Since we only show a preview before the first shipment,
                                the more feedback you can provide the better!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{color: '#a36b7a'}} align={'center'}>
                                Please select a reason for each piece before moving on
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justify={"center"}>
                            <Grid item>
                            <Button variant="contained"
                                    color="secondary"
                                    size="large"
                                    disabled={!this.reasonsIsFilled()}
                                    onClick={() => this.nextClick()}>Next</Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>)
                }
                { (this.state.status == ORDER_OUT_FOR_DELIVERY_STATUS) &&
                    <Grid item xs={12} container justify={"flex-end"} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >Leave a note for your stylist!</Typography>
                            <Typography style={{fontSize: 12}}>
                                Since we only send a preview before the first shipment, please tell us the type of
                                items you'd love to see in your next shipment or how we can make this a better
                                experience for you.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                            variant="outlined"
                            label=""
                            fullWidth={true}
                            multiline
                            rows={5}
                            inputRef={this.noteRef}
                            //className={classes.feedbackInput}
                            defaultValue={ this.props.item.checkout_feedback }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{color: '#a36b7a'}} align={'center'}>
                                Please select a reason for each piece before moving on
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justify={"center"}>
                            <Grid item>
                                <Button variant="contained"
                                        color="secondary"
                                        size="large"
                                        disabled={!this.reasonsIsFilled()}
                                        onClick={() => this.saveCheckoutPicks()}>Checkout</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                }
             </Grid>
          </Grid>
            <Snackbar
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={this.state.openSnackbar}
              autoHideDuration={2000}
              message={this.state.snackbarMessage}
              onClose={this.handleClose}
            />
            {this.state.showImage && (
                <ImageViewer
                    src={ [this.state.showImage]}
                    currentIndex={ 0 }
                    onClose={ this.closeImageViewer }
                />
            )}
        </React.Fragment>
    );
  }
}

export default Pick
